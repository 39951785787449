import {Injectable} from "@angular/core";
import {EntityStoreBaseService} from "shared-modules";
import {EntityCollectionServiceElementsFactory} from "@ngrx/data";

@Injectable({providedIn: 'root'})
export class SecretEntityService extends EntityStoreBaseService<Secret> {
  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('Secret', serviceFactory);
  }
}

export interface Secret {
  id: string;
  name: string;
  description: string;
  encryptedData: { [key: string]: string };
  sharedWith: { id: number, name?: string }[];
  ownerId: number;
  isMine: boolean;
}
