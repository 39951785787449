import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, switchMap} from "rxjs";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {first} from "rxjs/operators";
import {selectSettlementContext} from "../../store/core/core.selectors";
import {SESSION_INITIALIZE_ENDPOINT} from "../../store/core/core.effects";
import {environment} from "../../../../environments/environment";
import {CORE_CONFIG} from "../../consts/injection-tokens";

export class SettlementIdInterceptor implements HttpInterceptor {
  private store = inject(Store);
  private config = inject(CORE_CONFIG);
  private optionalUrls = [environment.apiUrl+SESSION_INITIALIZE_ENDPOINT, this.config.authConfig.apiUrl];
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectSettlementContext)
      .pipe(
        first((settlementId) => {
          if (this.optionalUrls.find((url) => req.url.startsWith(url))) {
            return true;
          }
          return !!settlementId;
        }),
        switchMap((settlementId) => {
          if (!settlementId) {
            return next.handle(req);
          }
          const queryParamName = 'settlementId';
          const cloned = req.params.has(queryParamName) ? req : req.clone({
            params: req.params.set(queryParamName, settlementId),
          });
          return next.handle(cloned);
        })
      );
  }
}
