<div [formGroup]="form">
  <mat-checkbox
    [required]="config.required"
    [formControlName]="config.name"
    [indeterminate]="config.defaultValue === undefined && config.indeterminate"
    [labelPosition]="config.labelPosition">
    {{config.label}}{{config.label && config.required ? '*' : ''}}
  </mat-checkbox>
  <mat-error *ngIf="control.hasError('required')">Please check the box.</mat-error>
</div>
