import {Component, inject, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {selectAuthError, selectAuthLoading} from "../../store/auth.selectors";
import {AuthActions} from "../../store/action-types";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private store = inject(Store);
  private cd = inject(ChangeDetectorRef);

  public loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  public isLoggingIn$ = this.store.select(selectAuthLoading);
  public loginError$ = this.store.select(selectAuthError)
    .pipe(
      filter((err): err is string => !!err),
      tap(() => {
        this.loginForm.enable();
        this.cd.detectChanges();
      }),
    );
  public selectedIndex$ = this.route.queryParamMap.pipe(
    map((qp) => qp.get('tabIndex')),
    map((tabIndex) => {
      if (!tabIndex) {
        return 0;
      }
      return Number(tabIndex);
    })
  );

  public tabIndexChange(index: number) {
    this.router.navigate([], {queryParams: {tabIndex: index}, queryParamsHandling: 'merge'});
  }

  public login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.loginForm.disable();
    const { username, password } = this.loginForm.value as { username: string, password: string };
    this.store.dispatch(AuthActions.loginWithCredentials({ username, password }));
  }

  public employeeLogin(): void {
    this.store.dispatch(AuthActions.loginWithMsal());
  }
}
