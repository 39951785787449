import {Injectable, inject} from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthService} from '../service/auth.service';
import {AuthActions} from './action-types';
import {Router, ActivatedRoute} from '@angular/router';
import {AUTH_CONFIG, getRemainingHours} from '../consts';
import {AuthToken} from '../interfaces';
import {TokenStorageError} from '../enums';
import {MsalService} from "@azure/msal-angular";

const AUTH_TOKEN_STORAGE_KEY = 'authToken';

/**
 * Returns an unexpired AuthToken from local storage or a string error message.
 */
const getCachedToken = (): AuthToken | string => {
  try {
    const storedToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    const parsedToken: AuthToken | null = storedToken ? JSON.parse(storedToken) : null;
    if (parsedToken) {
      const remainingHours = getRemainingHours(parsedToken.expires);
      if (remainingHours === 0) {
        return TokenStorageError.Expired;
      }
    }
    return parsedToken || TokenStorageError.Empty;
  } catch (error) {
    return TokenStorageError.ParseFailure;
  }
}

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private config = inject(AUTH_CONFIG);
  private msalService = inject(MsalService);

  public checkAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkAuth),
      switchMap(() => this.msalService.initialize()),
      tap(() => this.setActiveAccount()),
      map(() => {
        const msalAccount = this.msalService.instance.getActiveAccount();
        if (msalAccount) {
          return AuthActions.checkAuthComplete({ authenticated: true, token: null });
        }

        const cachedToken = getCachedToken();
        if (typeof cachedToken !== 'string') {
          return AuthActions.checkAuthComplete({ authenticated: true, token: cachedToken });
        }

        return AuthActions.checkAuthComplete({ authenticated: false, token: null });
      })
    )
  );

  public loginTraditional$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithCredentials),
      switchMap(({username, password}) =>
        this.authService.login(username, password).pipe(
          map((token) => AuthActions.loginWithCredentialsSuccess({ token, password })),
          catchError((error) => of(AuthActions.loginWithCredentialsFailure({ error: error.error?.message || error.message })))
        )
      )
    )
  );

  public loginMsal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithMsal),
      switchMap(() =>
        this.msalService.loginPopup().pipe(
          map(() => AuthActions.loginWithMsalSuccess()),
          catchError((error) => of(AuthActions.loginWithMsalFailure({ error: error.error?.message || error.message })))
        )
      )
    )
  );

  public loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.loginWithCredentialsSuccess,
        AuthActions.loginWithMsalSuccess,
      ),
      tap((action) => {
        this.router.navigateByUrl(this.route.snapshot.queryParamMap.get('redirectUrl') || '/');
        switch (action.type) {
          case AuthActions.loginWithCredentialsSuccess.type:
            localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(action.token));
            break;
          case AuthActions.loginWithMsalSuccess.type:
            this.setActiveAccount();
            break;
        }
      })
    ), { dispatch: false });

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap((action) => {
        const redirectUrl = action.redirectUrl ? this.router.url : undefined;
        const queryParams: any = redirectUrl !== '/' ? { redirectUrl } : { };
        const account = this.msalService.instance.getActiveAccount();
        if (account) {
          queryParams.tabIndex = 1;
          this.msalService.logoutPopup({account});
        }
        this.router.navigate([this.config.loginRoute], { queryParams });
        localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
      })
    ), { dispatch: false }
  );

  public refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      exhaustMap(() =>
        this.authService.refreshToken().pipe(
          map((token) => AuthActions.refreshTokenSuccess({ token })),
          catchError(() => of(AuthActions.refreshTokenFailure())),
        )
      ),
    ),
  );

  public refreshTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshTokenSuccess),
      tap(({token}) => localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify(token)))
    ), { dispatch: false }
  );

  private setActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length) {
        this.msalService.instance.setActiveAccount(accounts[0]);
      }
    }
  }
}
