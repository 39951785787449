import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from "@ngrx/store";
import {AuthActions} from "auth";
import {selectUser} from "../../store/core/core.selectors";
import {AsyncPipe, NgFor, NgIf, NgOptimizedImage} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {MatToolbarModule} from "@angular/material/toolbar";
import {UserMenuItemCallback, UserMenuItemRouted} from "../../interface/user-menu.interface";
import {CORE_CONFIG} from "../../consts/injection-tokens";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    RouterModule,
    NgOptimizedImage,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
  ],
})
export class UserMenuComponent {
  private store = inject(Store);
  private config = inject(CORE_CONFIG);
  public routeMenu: UserMenuItemRouted[] = [
    {
      label: 'User Profile',
      icon: 'account_circle',
      route: '/profile',
    },
    ...(this.config.userMenu || []),
  ];
  public callbackMenu: UserMenuItemCallback[] = [
    {
      label: 'Sign Out',
      icon: 'logout',
      callback: () => this.logout(),
    }
  ];
  public user$ = this.store.select(selectUser);
  public logout() {
    this.store.dispatch(AuthActions.logout({}));
  }
}
