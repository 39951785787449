import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {combineLatest, filter, map, of, startWith, switchMap} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {first, shareReplay} from "rxjs/operators";
import {FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {collectionToKeyValue$, ConfirmDialogComponent, ConfirmDialogData, isTruthy} from "caig-utils";
import {
  BaseField,
  CheckboxField,
  CurrencyField,
  DynamicFormModule,
  InputField, PhoneNumberField,
  SelectField,
  TextareaField
} from "dynamic-form";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {participationRowPainter, yesOrNo} from "../../core/consts/util";
import {Store} from "@ngrx/store";
import {EnumsActions} from "../../core/store/enums/action-types";
import {
  selectBueLocals,
  selectBueLocations,
  selectBueRegions,
  selectEmployeeStatusesFlat, selectParticipationStatuses, selectTags
} from "../../core/store/enums/enums.selectors";
import {selectSessionSettlements} from "../../core/store/core/core.selectors";
import {SettlementUserEntityService} from "../../core/service/entity-data/settlement-user-entity.service";
import {CustomizeEventComponent} from "../customize-event/customize-event.component";

@Component({
  selector: 'lib-edit-employee',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    NgForOf,
    NgIf,
    RouterLink,
    MatGridListModule,
    MatCardModule,
    DynamicFormModule,
    CustomizeEventComponent,
  ],
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEmployeeComponent {
  private dataService = inject(EmployeeEntityService);
  private settlementUserService = inject(SettlementUserEntityService);
  private route = inject(ActivatedRoute);
  private breakpointObserver = inject(BreakpointObserver);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private store = inject(Store);

  form: FormGroup = new FormGroup({});
  isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(({matches}) => matches),
      shareReplay(1),
    );
  gridColumns$ = this.isHandset$
    .pipe(
      map((isHandset) => isHandset ? 1 : 12)
    );
  employee$ = this.route.params
    .pipe(
      map((params) => params['id']),
      switchMap((employeeId) => this.dataService.getByKey(employeeId)),
      shareReplay(1),
    );
  disableSave$ = combineLatest([this.dataService.loading$, this.form.statusChanges])
    .pipe(
      map(([loading, status]) => loading || status !== 'VALID'),
      startWith(true),
    );
  toolbarButtons: any[] = [
    {
      label: 'Employee',
      icon: 'chevron_left',
      routerLink: '../',
    }
  ];
  sections: EditSection[] = [
    {
      cols: 6,
      rowspan: 49,
      title: 'Name',
      fields: [
        [
          new CheckboxField({
            name: 'deceased',
            label: 'Deceased',
          })
        ],
        [
          new InputField({
            name: 'firstName',
            label: 'First',
            required: true,
          }),
          new InputField({
            name: 'middleName',
            label: 'Middle',
          }),
          new InputField({
            name: 'lastName',
            label: 'Last',
            required: true,
          }),
          new InputField({
            name: 'suffix',
            label: 'Suffix',
          }),
        ],
        [
          new InputField({
            name: 'bueId',
            label: 'BUE ID',
          }),
          new SelectField({
            name: 'bueCurrent',
            label: 'BUE Current?',
            options: yesOrNo,
            deselect: true,
          }),
          new SelectField({
            name: 'bueMember',
            label: 'BUE Member?',
            options: yesOrNo,
            deselect: true,
          })
        ],
        [
          new SelectField({
            name: 'bueRegion',
            label: 'BUE Region',
            options: this.store.select(selectBueRegions).pipe(filter(isTruthy)),
            deselect: true,
          }),
          new SelectField({
            name: 'bueLocal',
            label: 'BUE Local',
            options: this.store.select(selectBueLocals).pipe(filter(isTruthy)),
            deselect: true,
          }),
          new SelectField({
            name: 'bueLocation',
            label: 'BUE Location',
            options: this.store.select(selectBueLocations).pipe(filter(isTruthy)),
            deselect: true,
          }),
        ],
        [
          new SelectField({
            name: 'bueStartYear',
            label: 'BUE Start Year',
            options: [],
            deselect: true,
          }),
          new SelectField({
            name: 'bueStartQuarter',
            label: 'BUE Start Quarter',
            options: [],
            deselect: true,
          }),
          new SelectField({
            name: 'bueEndYear',
            label: 'BUE End Year',
            options: [],
            deselect: true,
          }),
          new SelectField({
            name: 'bueEndQuarter',
            label: 'BUE End Quarter',
            options: [],
            deselect: true,
          }),
        ],
        [
          new InputField({
            inputType: 'number',
            name: 'busCode',
            label: 'BUS Code',
          }),
          new InputField({
            inputType: 'number',
            name: 'grade',
            label: 'Grade',
          }),
          new InputField({
            inputType: 'number',
            name: 'step',
            label: 'Step',
          }),
          new InputField({
            inputType: 'number',
            name: 'series',
            label: 'Series',
          }),
        ],
      ],
    },
    {
      cols: 3,
      rowspan: 49,
      title: 'Tags',
      fields: [
        [
          new SelectField({
            label: 'Tags',
            name: 'tags',
            options: this.store.select(selectTags).pipe(filter(isTruthy)),
            multiple: true,
          })
        ]
      ],
    },
    {
      cols: 3,
      rowspan: 49,
      title: 'Other Info',
      fields: [
        [
          new TextareaField({
            name: 'notes',
            label: 'Notes',
          })
        ]
      ],
    },
    {
      cols: 6,
      rowspan: 28,
      title: 'Address',
      fields: [
        [
          new CheckboxField({
            name: 'addressIsInvalid',
            label: 'This address is invalid',
          })
        ],
        [
          new InputField({
            name: 'address1',
            label: 'Line 1',
          }),
          new InputField({
            name: 'address2',
            label: 'Line 2',
          }),
        ],
        [
          new InputField({
            name: 'city',
            label: 'City',
          }),
          new InputField({
            name: 'state',
            label: 'State',
          }),
          new InputField({
            name: 'zip',
            label: 'Zip',
          }),
        ],
      ],
    },
    {
      cols: 3,
      rowspan: 28,
      title: 'Phone',
      fields: [
        [
          new CheckboxField({
            name: 'phoneIsInvalid',
            label: 'None of the phones are valid',
          })
        ],
        [
          new PhoneNumberField({
            name: 'phone',
            label: 'Phone',
            hint: { message: '', align: 'start' },
          }),
          new PhoneNumberField({
            name: 'phoneCell',
            label: 'Phone Cell',
            hint: { message: '', align: 'start' },
          }),
        ],
        [
          new PhoneNumberField({
            name: 'phoneWork',
            label: 'Phone Work',
            ext: true,
            hint: { message: '', align: 'start' },
          }),
        ]
      ],
    },
    {
      cols: 3,
      rowspan: 28,
      title: 'Email',
      fields: [
        [
          new InputField({
            name: 'email',
            label: 'Email',
          }),
          new CheckboxField({
            name: 'emailIsInvalid',
            label: 'Email is invalid',
          })
        ],
        [
          new InputField({
            name: 'emailAlt',
            label: 'Alternative Email',
          }),
          new CheckboxField({
            name: 'emailAltIsInvalid',
            label: 'Alt email is invalid',
          })
        ]
      ],
    },
    {
      cols: 6,
      rowspan: 14,
      title: 'Federal Taxes',
      fields: [
        [
          new CheckboxField({
            name: 'fedExempt',
            label: 'Fed. tax exempt',
          }),
          new CheckboxField({
            name: 'fedSsExempt',
            label: 'Soc. security exempt',
          }),
          new CheckboxField({
            name: 'fedMcExempt',
            label: 'Medicare exempt',
          }),
          new InputField({
            inputType: 'number',
            name: 'fedAddlamt',
            label: 'Additional federal tax (negative amount)',
            placeholder: '0.00',
          })
        ],
      ],
    },
    {
      cols: 3,
      rowspan: 14,
      title: 'State Taxes',
      fields: [
        [
          new CheckboxField({
            name: 'stateExempt',
            label: 'State tax exempt',
          }),
          new InputField({
            inputType: 'number',
            name: 'stateAddlamt',
            label: 'Additional state tax (negative amount)',
            placeholder: '0.00',
          })
        ],
      ],
    },
    {
      cols: 3,
      rowspan: 14,
      title: 'Donation',
      fields: [
        [
          new InputField({
            inputType: 'number',
            name: 'donation',
            label: 'Donation (positive amount)',
            placeholder: '0.00',
          })
        ]
      ],
    },
    {
      cols: 6,
      rowspan: 22,
      title: 'Money',
      fields: [
        [
          new CurrencyField({
            name: 'spotBp',
            label: 'SPOT BP',
          }),
          new CurrencyField({
            name: 'ctotBp',
            label: 'CTOT BP',
          }),
          new CurrencyField({
            name: 'spotLd',
            label: 'SPOT LD',
          }),
          new CurrencyField({
            name: 'ctotLd',
            label: 'CTOT LD',
          }),
        ],
        [
          new CurrencyField({
            name: 'estCostShare',
            label: 'Cost Share',
          }),
        ]
      ],
    },
    {
      cols: 2,
      rowspan: 22,
      title: 'Adjustments',
      fields: [
        [
          new CurrencyField({
            name: 'addlamt',
            label: 'Negative reduces net check'
          })
        ]
      ],
    },
    {
      cols: 2,
      rowspan: 22,
      title: 'Case',
      fields: [
        [
          new SelectField({
            name: 'settlementId',
            label: 'Case',
            options: collectionToKeyValue$(this.store.select(selectSessionSettlements), 'code', 'id'),
            required: true,
          })
        ]
      ],
    },
    {
      cols: 2,
      rowspan: 22,
      title: 'Status',
      fields: [
        [
          new SelectField({
            name: 'status',
            label: 'Status',
            options: collectionToKeyValue$(this.store.select(selectEmployeeStatusesFlat), 'displayName', 'name'),
            deselect: true,
          })
        ]
      ],
    },
    {
      cols: 3,
      rowspan: 14,
      title: 'Assignments',
      fields: [
        [
          new SelectField({
            name: 'userId',
            label: 'User',
            options: collectionToKeyValue$(this.settlementUserService.entities$, 'name', 'id'),
            deselect: true,
          }),
        ]
      ],
    },
    {
      cols: 3,
      rowspan: 14,
      title: 'Participation',
      fields: [
        [
          new SelectField({
            name: 'participationStatus',
            label: 'Participation Status',
            options: this.store.select(selectParticipationStatuses).pipe(filter(isTruthy)),
            deselect: true,
            optionColor: (option) => participationRowPainter(option.value),
          }),
        ]
      ],
    },
  ];

  constructor() {
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueRegions'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueLocals'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'bueLocations'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'employeeStatus'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'participationStatuses'}));
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'tags'}));
    this.settlementUserService.loadIfNeeded();
  }

  save(addEvent: {eventCode?: number, eventMessage?: string}) {
    this.employee$
      .pipe(
        first(),
        switchMap((employee) => {
          if (employee.settlementId === this.form.value.settlementId) {
            return of(employee);
          }
          const data: ConfirmDialogData = { title: 'Warning!', text: 'Are you sure you want to change the case for this employee? You will no longer see this employee under the current case.' };
          return this.dialog.open(ConfirmDialogComponent, {data})
            .afterClosed()
            .pipe(
              filter(isTruthy),
              map(() => employee),
            );
        }),
        switchMap((employee) => this.dataService.update({...employee, ...this.form.value, ...addEvent})),
      )
      .subscribe((employee) => {
        this.router.navigate(['../'], {queryParamsHandling: 'preserve', relativeTo: this.route});
      });
  }
}

interface EditSection {
  cols: number;
  rowspan: number;
  title: string;
  fields: BaseField<any>[][];
}
