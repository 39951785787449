<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <app-user-menu></app-user-menu>
    <app-navigation-menu></app-navigation-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="flex-fill flex-column">
      <mat-toolbar color="primary" class="flex-align-spacebetween-center mat-elevation-z8">
        <div class="flex-row flex-align-start-center">
          <button mat-icon-button (click)="drawer.toggle()">
            <mat-icon>{{drawer.opened ? 'menu_open' : 'menu'}}</mat-icon>
          </button>
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="isHandset$ | async">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <a routerLink="/" mat-button aria-label="CAIG home page" class="home">CAIG</a>
          <app-case-selector *ngIf="session$ | async"></app-case-selector>
        </div>
        <div class="flex items-center gap-2">
          <button *ngIf="showDialer" mat-icon-button (click)="openDialer()" matTooltip="Dial phone number">
            <mat-icon>dialpad</mat-icon>
          </button>
          <lib-update-timer *ngIf="swService.isUpdateAvailable$ | async as update"
                            [updateEvent]="update"
                            [disabled]="swService.isUpdating"
                            (installUpdate)="swService.installUpdate()">
          </lib-update-timer>
          <app-theme-toggle></app-theme-toggle>
        </div>
      </mat-toolbar>
      <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate" color="accent"></mat-progress-bar>
      <div class="flex-1 outlet-container">
        <app-session-error *ngIf="sessionError$ | async; else showOutlet"></app-session-error>
        <ng-template #showOutlet>
          <router-outlet></router-outlet>
        </ng-template>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
