import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from "@azure/msal-angular";
import {MSAL_SCOPE} from "./consts";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '0be33617-ad2b-4dba-9fe6-f41d5716ee89',
      authority: 'https://login.microsoftonline.com/a9cd138e-0dc6-477f-842a-305b2b42489a',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, msg) => console.log(msg),
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['/api/*', [MSAL_SCOPE]],
    ]),
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [MSAL_SCOPE],
    },
    loginFailedRoute: '/',
  };
}
