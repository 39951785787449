import {AuthToken} from '../interfaces';

export interface AuthState {
  authenticated: boolean;
  token: AuthToken | null;
  loginPassword: string | null;
  error: string | null;
  loading: boolean;
}

export const initialAuthState: AuthState = {
  authenticated: false,
  token: null,
  loginPassword: null,
  error: null,
  loading: false,
};
