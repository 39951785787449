import {DataColumn} from "./types";
import {formatNumber, KeyValue} from "@angular/common";
import {filter, map, Observable} from "rxjs";

export function isNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

export function isEmpty(value: unknown): boolean {
  return value === '' || value === null || value === undefined;
}

export function exportToCSV<T = any>(data: T[], columns: DataColumn<T>[], filename: string): void {
  const array = typeof data != 'object' ? JSON.parse(data) : data;
  let csvData = '';
  let row = '';
  for (let index in columns) {
    row += columns[index].title + ',';
  }
  row = row.slice(0, -1);
  csvData += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in columns) {
      const head = columns[index].field;
      const value = array[i][head];
      const srtValue = value === undefined || value === null ? '' : typeof value === 'string' ? value : JSON.stringify(value);
      if (srtValue.replace(/ /g, '').match(/[\s,"]/)) {
        line += '"' + srtValue.replace(/"/g, '""') + '"';
      } else {
        line += srtValue;
      }
      line += ',';
    }
    csvData += line + '\r\n';
  }
  const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  const dwldLink = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute('target', '_blank');
  }
  dwldLink.setAttribute('href', url);
  dwldLink.setAttribute('download', filename + '.csv');
  dwldLink.style.visibility = 'hidden';
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

export function collectionToKeyValue<T, K extends keyof T, V extends keyof T>(keyProp: K, valueProp: V) {
  return (collection: T[]): KeyValue<T[K], T[V]>[] =>
    collection.map(item => ({
      key: item[keyProp],
      value: item[valueProp],
    }));
}

export function isTruthy<T>(value: T | null | undefined): value is T {
  return !!value;
}

export function collectionToKeyValue$<T, K extends keyof T, V extends keyof T>(
  obs$: Observable<T[] | null | undefined>,
  keyProp: K,
  valueProp: V
): Observable<KeyValue<T[K], T[V]>[]> {
  return obs$.pipe(
    filter(isTruthy),
    map(collectionToKeyValue<T, K, V>(keyProp, valueProp))
  );
}

export function zeroPad(value: number | undefined, padding: number): string {
  return value === undefined ? '' : formatNumber(value, 'en-us', `${padding}.0-0`).replace(',', '');
}

export function sortById<T extends {id: number}>(a: T, b: T): number {
  return a.id - b.id;
}

export function openFile(blob: Blob, filename: string): void {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url
  a.download = filename || '';
  document.body.append(a);
  a.dispatchEvent(new MouseEvent('click'));
  window.URL.revokeObjectURL(url);
  a.remove();
}

export function formatMonth(month?: number): string {
  if (month === undefined) {
    return '';
  }
  const months: {[key: number]: string} = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };
  return month === 0 ? 'Uncertain' : months[month];
}

export function formatYear(year?: number): string | number {
  if (year === undefined) {
    return '';
  }
  return year === 0 ? 'Uncertain' : year;
}
