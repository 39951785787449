import {NgModule} from "@angular/core";
import {DefaultDataServiceConfig, EntityDataModule, EntityDispatcherDefaultOptions, Pluralizer} from "@ngrx/data";
import {entityMetadata} from "./entity-metadata";
import {EffectsModule} from "@ngrx/effects";
import {baseDataServiceConfig, defaultDispatcherOptions, EntityEffects, EntityPluralizer} from "shared-modules";

@NgModule({
  imports: [
    EntityDataModule.forRoot({entityMetadata}),
    EffectsModule.forFeature([EntityEffects]),
  ],
  providers: [
    {provide: Pluralizer, useClass: EntityPluralizer},
    {provide: DefaultDataServiceConfig, useValue: baseDataServiceConfig },
    {provide: EntityDispatcherDefaultOptions, useValue: defaultDispatcherOptions },
  ],
})
export class EntityStoreModule {

}
