import {createAction, props} from '@ngrx/store';
import {AuthToken} from '../interfaces';

export const loginWithCredentials = createAction(
  '[Auth] Login with Credentials',
  props<{ username: string, password: string }>()
);

export const loginWithCredentialsSuccess = createAction(
  '[Auth] Login with Credentials Success',
  props<{ token: AuthToken, password?: string }>()
);

export const loginWithCredentialsFailure = createAction(
  '[Auth] Login with Credentials Failure',
  props<{ error: string }>()
);

export const loginWithMsal = createAction(
  '[Auth] Login with MSAL'
);

export const loginWithMsalSuccess = createAction(
  '[Auth] Login with MSAL Success',
);

export const loginWithMsalFailure = createAction(
  '[Auth] Login with MSAL Failure',
  props<{ error: string }>()
);

export const logout = createAction(
  '[Auth] Logout',
  props<{ redirectUrl?: boolean, expiredSession?: boolean }>(),
);

export const checkAuth = createAction('[Auth] Check Auth Status');
export const checkAuthComplete = createAction(
  '[Auth] Check Auth Status Complete',
  props<{ authenticated: boolean, token: AuthToken | null }>()
);

export const refreshToken = createAction('[Auth] Refresh Token');

export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ token: AuthToken }>()
);

export const refreshTokenFailure = createAction('[Auth] Refresh Token Failure');
