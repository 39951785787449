import {Injectable, inject} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import {Observable, catchError} from 'rxjs';
import {NotificationService} from 'notification';
import {MatDialog} from "@angular/material/dialog";
import {SidenavStackService} from "sidenav-stack";
import {Store} from "@ngrx/store";
import {AuthActions} from "auth";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private dialog = inject(MatDialog);
  private sidenavService = inject(SidenavStackService);
  private notification = inject(NotificationService);
  private store = inject(Store);
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.dialog.closeAll();
          this.sidenavService.closeAll();
          this.store.dispatch(AuthActions.logout({redirectUrl: true}));
        }
        this.notification.showDetailedMessage('An error has occurred', err);
        throw err;
      })
    );
  }
}
