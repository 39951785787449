import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  adminGuard,
  authConfig,
  entityCollectionResolver,
  initializeGuard,
  LayoutComponent,
  sessionGuard,
  SessionRole
} from "shared-modules";
import {authGuard, LoginComponent} from "auth";
import {SettlementEntityService} from "./services/entity-data/settlement-entity.service";
import {ThemedBackgroundComponent} from "caig-utils";
import {SecretEntityService} from "./services/entity-data/secret-entity.service";

const routes: Routes = [
  {
    path: '',
    canActivate: [initializeGuard],
    children: [
      {
        path: '',
        canActivate: [authGuard(true), sessionGuard],
        children: [
          {
            path: '',
            component: LayoutComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
              },
              {
                path: 'employees',
                loadChildren: () => import('shared-modules').then(m => m.EmployeesModule),
              },
              {
                path: 'events',
                loadChildren: () => import('shared-modules').then(m => m.EventsModule),
              },
              {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
                canActivate: [adminGuard(SessionRole.Administrator)]
              },
              {
                path: 'payrolls',
                loadChildren: () => import('./payrolls/payrolls.module').then(m => m.PayrollsModule),
              },
              {
                path: 'call-logs',
                loadChildren: () => import('./call-logs/call-logs.module').then(m => m.CallLogsModule),
                canActivate: [adminGuard(SessionRole.Superadmin)]
              },
              {
                path: 'cases',
                loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule),
                resolve: {
                  entities: entityCollectionResolver(SettlementEntityService),
                },
                canActivate: [adminGuard(SessionRole.Administrator)]
              },
              {
                path: 'profile',
                loadChildren: () => import('shared-modules').then(m => m.ProfileModule),
              },
              {
                path: 'configurations',
                loadChildren: () => import('./configurations/configurations.module').then(m => m.ConfigurationsModule),
                canActivate: [adminGuard(SessionRole.Superadmin)]
              },
              {
                path: 'users',
                loadChildren: () => import('shared-modules').then(m => m.UsersModule),
                canActivate: [adminGuard(SessionRole.Administrator)],
              },
              {
                path: 'secrets',
                loadChildren: () => import('./secrets/secrets.module').then(m => m.SecretsModule),
                canActivate: [adminGuard(SessionRole.Superadmin)],
                resolve: {
                  entities: entityCollectionResolver(SecretEntityService),
                }
              },
              {
                path: 'playground',
                loadComponent: () => import('./playground/playground.component').then(c => c.PlaygroundComponent),
                canActivate: [adminGuard(SessionRole.Superadmin)],
              },
              {
                path: 'voicemails',
                loadChildren: () => import('./voicemails/voicemails.module').then(m => m.VoicemailsModule),
              }
            ],
          },
        ],
      },
      {
        path: authConfig.loginRoute,
        component: ThemedBackgroundComponent,
        canActivate: [authGuard(false)],
        children: [
          {
            path: '',
            component: LoginComponent,
          }
        ]
      },
      { path: '**', loadChildren: () => import('shared-modules').then(m => m.PageNotFoundModule) },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
