import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {inject} from "@angular/core";
import {MsalService} from "@azure/msal-angular";
import {AuthActions} from "auth";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {SidenavStackService} from "sidenav-stack";

export class DecryptInterceptor implements HttpInterceptor {
  private msalService = inject(MsalService);
  private http = inject(HttpClient);
  private dialog = inject(MatDialog);
  private sidenavService = inject(SidenavStackService);
  private store = inject(Store);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const headerName = 'WWW-Authenticate';
        if (error.status === 401) {
          if (error.headers.has(headerName)) {
            const wwwAuthenticate = error.headers.get(headerName);
            const realmMatch = wwwAuthenticate?.match(/realm="?([^",]*)"?/i);
            if (realmMatch) {
              const unlockUrl = '/api/crypto/unlock' + (realmMatch[1] === RealmType.Secret ? '/secret' : '');
              switch (realmMatch[1]) {
                case RealmType.Crypto:
                case RealmType.Secret:
                  return this.msalService.initialize()
                    .pipe(
                      switchMap(() => this.msalService.acquireTokenPopup({scopes: ['https://vault.azure.net/user_impersonation']})),
                      map((response) => JSON.stringify(response.accessToken)),
                      switchMap((token) => this.http.post<void>(unlockUrl, token, {headers: {'Content-Type': 'application/json'}})),
                      switchMap(() => next.handle(req)),
                    );
              }
            }
          }
          this.dialog.closeAll();
          this.sidenavService.closeAll();
          this.store.dispatch(AuthActions.logout({redirect: true, expiredSession: true}));
        }
        throw error;
      }),
    );
  }
}

enum RealmType {
  Crypto='crypto',
  Secret='secret',
}
