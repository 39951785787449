import {ThemePalette} from "@angular/material/core";
import {Sort} from "@angular/material/sort";
import {VsTableColumnFilter} from "./column-filter";

export interface VsTableColumn<T = any> {
  field: Extract<keyof T, string>;
  title: string;
  dataType?: VsTableColumnDataType;
  sort?: boolean;
  hide?: boolean;
  sticky?: 'start' | 'end';
  align?: ColumnAlign;
  badge?: BadgeConfig<T>;
  sum?: boolean;
  format?: string;
  negateValue?: boolean;
  styles?: {[key: string]: string | number};
  calculate?: (row: T) => string;
  tooltip?: (row: T) => string;
  color?: (row: T) => string;
  backgroundColor?: (row: T) => string;
  compareField?: Extract<keyof T, string>;
  expandable?: boolean;
  draggable?: boolean;
  filter?: boolean;
  onClick?: (value: any, row: T, column: VsTableColumn<T>) => void;
  oldValueField?: string;
  newValueField?: string;
  changedField?: string;
  displayTitle?: boolean;
  theme?: ThemePalette;
}

export enum VsTableColumnDataType {
  Text = 'Text',
  Date = 'Date',
  Currency = 'Currency',
  Calculate = 'Calculate',
  Icon = 'Icon',
  Number = 'Number',
  Changes = 'Changes',
  Button = 'Button',
}

export type ColumnAlign = 'start' | 'center' | 'end';

export interface BadgeConfig<T = any> {
  value: (row: T) => string | number | undefined | null;
  color?: ThemePalette;
}

export interface VsTableMenuItem<T> {
  name: (data: T) => string;
  callback: (data: T) => void;
  hide?: (data: T) => boolean;
  disable?: (data: T) => boolean;
}

export type VsTableColumnConfig<T> = Omit<VsTableColumn<T>, 'dataType'>;

export type VsTableColumnConfigCalculate<T> = Omit<VsTableColumn<T>, 'calculate'> & Required<Pick<VsTableColumn<T>, 'calculate'>>;

export type VsTableColumnConfigButton<T> = Omit<VsTableColumnConfigCalculate<T>, 'onClick'> & Required<Pick<VsTableColumnConfigCalculate<T>, 'onClick'>>;

export interface VsTableCellChange {
  field: string;
  oldValue: any;
  newValue: any;
}

export interface VsTableCacheState<T> {
  columns?: Pick<VsTableColumn<T>, 'field' | 'hide'>[];
  filter?: string;
  columnFilters?: {[field: string]: VsTableColumnFilter<T>};
  scrollTop?: number;
  sort?: Sort;
}

export interface VsTableExpandedDetailConfig<T> {
  row: T;
  column: VsTableColumn<T>;
  rowHeight: number;
  index: number;
  rowName: ((row: T) => string) | null | undefined;
}

export interface VsTableRowClick<T> {
  row: T;
  index: number;
}

export interface VsTableFilterSortChange {
  filtered: boolean;
  sorted: boolean;
}

export interface VsTableFilterSortState<T> {
  filter: string;
  sort: Sort;
  columnFilters: {[field: string]: VsTableColumnFilter<T>};
}
