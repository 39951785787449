import {createReducer, on} from "@ngrx/store";
import {initialEmployeeState} from "./employee.state";
import {EmployeeActions} from "./action-types";

export const employeeReducers = createReducer(
  initialEmployeeState,
  on(EmployeeActions.createBatch, (state) => ({
    ...state,
    loading: true,
  })),
  on(EmployeeActions.createBatchError, (state) => ({
    ...state,
    loading: false,
  })),
  on(EmployeeActions.batchCreated, (state, {batchId}) => ({
    ...state,
    batchId,
    loading: false,
  })),
  on(EmployeeActions.loadBatch, (state) => ({
    ...state,
    loading: true,
  })),
  on(EmployeeActions.batchLoadError, (state) => ({
    ...state,
    loading: false,
  })),
  on(EmployeeActions.batchLoaded, (state, {employees}) => ({
    ...state,
    employees,
    loading: false,
  })),
  on(EmployeeActions.clearBatch, (state) => ({
    ...initialEmployeeState,
    batchId: null,
  })),
);
