import {inject, Injectable} from "@angular/core";
import {MsalInterceptor} from "@azure/msal-angular";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable, switchMap} from "rxjs";
import {Store} from "@ngrx/store";
import {selectAuthToken} from "../store/auth.selectors";
import {first} from "rxjs/operators";

@Injectable()
export class CustomMsalInterceptor extends MsalInterceptor {
  private store = inject(Store);
  override intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthToken).pipe(
      first(),
      switchMap((token) =>
        token ? next.handle(req) : super.intercept(req, next)
      )
    );
  }
}
