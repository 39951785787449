import {InputField} from "./class/input-field";
import {DateField} from "./class/date-field";
import {DateRangeField} from "./class/date-range-field";
import {CurrencyField} from "./class/currency-field";
import {AutocompleteField} from "./class/autocomplete-field";
import {CheckboxField} from "./class/checkbox-field";
import {RadioField} from "./class/radio-field";
import {Observable} from "rxjs";
import {KeyValue} from "@angular/common";
import {SelectField} from "./class/select-field";
import {NumberRangeField} from "./class/number-range-field";
import {TextareaField} from "./class/textarea-field";
import {PhoneNumberField} from "./class/phone-number-field";

export type DynamicFormField =
  InputField |
  DateField |
  DateRangeField |
  CurrencyField |
  AutocompleteField<any> |
  CheckboxField |
  RadioField |
  SelectField |
  NumberRangeField |
  TextareaField |
  PhoneNumberField
;

export enum FieldType {
  Input,
  Date,
  DateRange,
  Currency,
  Autocomplete,
  Checkbox,
  Radio,
  Select,
  NumberRange,
  Textarea,
  PhoneNumber,
  // Chips,

  // @TODO

  // SlideToggle,
  // ButtonToggle,
  // Slider,
  // SSN,
}

export type FlexJustify = 'flex-start' | 'center' | 'flex-end';

export type FieldOptions =
  (string | number | boolean | null)[] |
  Observable<(string | number | boolean | null)[]> |
  KeyValue<string | number | boolean | null, any>[] |
  Observable<KeyValue<string | number | boolean | null, any>[]>
;
