import {AppPortal, CoreConfig, EmployeeEntityService, SessionRole, SettlementUserEntityService} from "shared-modules";
import {authConfig} from "./auth.config";

export const coreConfig: CoreConfig = {
  authConfig: authConfig,
  portal: AppPortal.CAIG,
  navigationMenu: [
    {
      roleIds: [
        SessionRole.User,
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Employees',
          route: 'employees',
          icon: 'badge',
        },
        {
          label: 'Events',
          route: 'events',
          icon: 'receipt_long',
        },
        {
          label: 'Payrolls',
          route: 'payrolls',
          icon: 'payments',
        },
        {
          label: 'Voicemails',
          route: '/voicemails',
          icon: 'voicemail'
        }
      ],
    },
    {
      title: 'Admin Menu',
      roleIds: [
        SessionRole.Administrator,
        SessionRole.Superadmin,
      ],
      menu: [
        {
          label: 'Cases',
          route: 'cases',
          icon: 'gavel',
        },
        {
          label: 'Reports',
          route: 'reports',
          icon: 'topic',
        },
        {
          label: 'Users',
          route: 'users',
          icon: 'people',
        },
      ],
    },
    {
      title: 'Super-Admin Menu',
      roleIds: [SessionRole.Superadmin],
      menu: [
        {
          label: 'Call Logs',
          route: 'call-logs',
          icon: 'contact_phone',
        },
        {
          label: 'Configurations',
          route: 'configurations',
          icon: 'settings'
        }
      ],
    }
  ],
  userMenu: [
    {
      label: 'My Secrets',
      icon: 'password',
      route: '/secrets',
      roleId: SessionRole.Superadmin,
    },
    {
      label: 'Playground',
      icon: 'sports_esports',
      route: '/playground',
      roleId: SessionRole.Superadmin,
    }
  ],
  resetEntitiesServices: [
    EmployeeEntityService,
    SettlementUserEntityService,
  ]
};
