<mat-card class="h-full w-full mat-elevation-z10">
  <mat-card-header class="mb-4">
    <mat-card-title>
      <div class="text-2xl font-semibold">Email Preview</div>
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="flex flex-grow flex-col">
    <div *ngIf="employees && employees.length" class="flex justify-start items-center gap-2 mt-4">
      <div class="w-80">
        <dynamic-form [fields]="[[employeeField]]"></dynamic-form>
      </div>
      <div *ngIf="employees.length > 1" class="text-lg opacity-75">+ <b>{{employees.length - 1}}</b> other{{employees.length === 2 ? '' : 's'}}</div>
    </div>
    <div class="grid grid-cols-[auto,1fr] gap-4 my-4">
      <div class="opacity-75">TO:</div>
      <div>{{preview?.to}}</div>
      <div class="opacity-75">FROM:</div>
      <div>{{preview?.from}}</div>
      <div class="opacity-75">CC:</div>
      <div>{{settlement?.adminCc}}</div>
      <div class="opacity-75">SUBJECT:</div>
      <div *ngIf="preview" class="font-semibold" [innerHTML]="preview.subjectRendered | safe:'html'"></div>
    </div>
    <mat-divider></mat-divider>
    <div class="mt-4">
      <div class="opacity-75 mb-2">BODY:</div>
      <div *ngIf="preview" [innerHTML]="preview.bodyRendered | safe:'html'"></div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions align="end" class="h-{{employees ? 48 : 28}}">
    <div class="flex flex-col">
      <mat-checkbox *ngIf="employees" labelPosition="before" [(ngModel)]="includeAltEmail">Send to primary <span class="italic font-bold">and</span> alternate email addresses</mat-checkbox>
      <lib-customize-event #toggle="customizeEvent" eventType="eventTypesEmail" [defaultEventCode]="defaultEventCode || 301"></lib-customize-event>
    </div>
    <button class="ml-12"
            mat-raised-button
            color="primary"
            [disabled]="!preview || (toggle.isActive && toggle.form.invalid)"
            (click)="emitSend(toggle)">SEND EMAIL</button>
  </mat-card-actions>
</mat-card>
