<div *ngIf="((entityIndex === null ? -1 : entityIndex) + 1) as index" class="w-full overflow-x-auto overflow-y-hidden h-14">
  <div *ngIf="entities && entities.length" class="flex flex-grow justify-between items-center gap-5">
    <button mat-raised-button [disabled]="isLoading || entities.length < 2 || index === 1" (click)="cycleTo(entities, index - 2)">Previous</button>
    <div class="text-center">
      <div>Displaying record {{index}} of {{entities.length}} records</div>
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </div>
    <button mat-raised-button [disabled]="isLoading || entities.length < 2 || index === entities.length" (click)="cycleTo(entities, index)">Next</button>
  </div>
</div>
