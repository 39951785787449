<div class="flex flex-grow gap-4">
  <mat-form-field class="flex flex-grow" [subscriptSizing]="config.subscriptSizing" [formGroup]="form" [appearance]="config.appearance" [color]="config.color">
    <mat-label>{{config.label}}</mat-label>
    <input [type]="config.inputType"
           [id]="config.id || ''"
           [formControlName]="config.name"
           [placeholder]="config.placeholder"
           [readonly]="config.isReadonly"
           (input)="removeNegativeSign(inputRef)"
           [min]="config.disableNegative ? 0 : undefined"
           #inputRef="matInput"
           autocomplete="off"
           matInput>
    <mat-hint *ngIf="config.hint as hint" [align]="hint.align || 'start'">{{hint.message}}</mat-hint>
    <mat-error *ngIf="control.hasError('required')">{{config.label}} is required.</mat-error>
    <mat-error *ngIf="control.hasError('email')">Enter a valid email address.</mat-error>
    <button *ngIf="control.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            [disabled]="control.disabled"
            (click)="control.patchValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button *ngFor="let button of config.buttons"
          [matTooltip]="button.tooltip || ''"
          type="button"
          mat-icon-button
          [color]="button.color"
          (click)="button.callback(control.value)"
          [disabled]="!!(control.disabled || (button.disable && button.disable(control.value)))">
    <mat-icon>{{button.icon}}</mat-icon>
  </button>
</div>
