<div class="login">
  <mat-card class="login-card mat-elevation-z6">
    <mat-toolbar color="primary" class="mat-elevation-z6">Class Action Implementation Group</mat-toolbar>
    <mat-tab-group [selectedIndex]="selectedIndex$ | async" (selectedIndexChange)="tabIndexChange($event)" backgroundColor="primary">
      <mat-tab label="Standard User">
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
          <mat-form-field class="full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <mat-error>Username is required.</mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password">
            <mat-error>Password is required.</mat-error>
          </mat-form-field>
          <button mat-raised-button color="accent" type="submit" class="full-width" [disabled]="loginForm.disabled || loginForm.invalid || (isLoggingIn$ | async)">SIGN IN</button>
        </form>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img style="margin-right: 8px" width="50" height="50" src="https://lfmxstagestore.blob.core.windows.net/content/unnamed.png" />
          Employee
        </ng-template>
        <div class="login-form p-4">
          <button mat-raised-button (click)="employeeLogin()" [disabled]="isLoggingIn$ | async">
            <div class="flex w-full gap-10 justify-start items-center">
              <img src="https://lfmxstagestore.blob.core.windows.net/content/ms-symbollockup_mssymbol_19.svg" />
              <div>Sign in with Microsoft</div>
            </div>
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
    <mat-progress-bar *ngIf="isLoggingIn$ | async; else notLoggingIn" mode="indeterminate" color="primary"></mat-progress-bar>
    <ng-template #notLoggingIn>
      <lib-login-error *ngIf="loginError$ | async as msg" [message]="msg"></lib-login-error>
    </ng-template>
  </mat-card>
</div>
