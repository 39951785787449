import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EnumsState} from "./enums.state";
import {EmployeeStatus, EmployeeStatusFlat} from "../../interface/employees.interface";

export const enumsFeatureKey = 'enums';
export const selectEnumsState = createFeatureSelector<EnumsState>(enumsFeatureKey);
export const selectEmployeeStatuses = createSelector(
  selectEnumsState,
  (state) => state.employeeStatus?.values,
);

export const selectEmployeeStatusesFlat = createSelector(
  selectEmployeeStatuses,
  (statuses) => {
    if (!statuses) {
      return statuses;
    }
    const statusesFlat: EmployeeStatusFlat[] = [];
    const flattenStatuses = (es: EmployeeStatus[], level = 0, parent?: EmployeeStatus) => {
      es.forEach((status) => {
        statusesFlat.push({
          ...status,
          parent,
          displayName: '- '.repeat(level) + status.name,
        });
        if (status.subStatuses.length) {
          flattenStatuses(status.subStatuses, level + 1, status);
        }
      })
    };
    flattenStatuses(statuses);
    return statusesFlat;
  },
)

export const selectSettlementStates = createSelector(
  selectEnumsState,
  (state) => state.settlementStates?.values,
);

export const selectBueLocations = createSelector(
  selectEnumsState,
  (state) => state.bueLocations?.values,
);

export const selectBueLocals = createSelector(
  selectEnumsState,
  (state) => state.bueLocals?.values,
);

export const selectBueRegions = createSelector(
  selectEnumsState,
  (state) => state.bueRegions?.values,
);

export const selectEventTypesPortal = createSelector(
  selectEnumsState,
  (state) => state.eventTypesPortal?.values,
);

export const selectEventTypesEmail = createSelector(
  selectEnumsState,
  (state) => state.eventTypesEmail?.values,
);

export const selectCallTypes = createSelector(
  selectEnumsState,
  (state) => state.callTypes?.values,
);

export const selectUserRoles = createSelector(
  selectEnumsState,
  (state) => state.userRoles?.values,
);

export const selectParticipationStatuses = createSelector(
  selectEnumsState,
  (state) => state.participationStatuses?.values,
);

export const selectTags = createSelector(
  selectEnumsState,
  (state) => state.tags?.values,
);

export const selectAllStates = createSelector(
  selectEnumsState,
  (state) => state.allStates?.values,
);
