<div class="h-full w-full flex flex-col min-h-64 relative">
  <mat-form-field *ngIf="!disableToolbar" appearance="fill" subscriptSizing="dynamic" class="w-full">
    <mat-label>Search {{tableDataSource.data.length}} {{entityName}}</mat-label>
    <input #search matInput [value]="tableDataSource.filter" (keyup)="applyFilter(search.value)">
    <div matPrefix class="p-3">
      <mat-icon class="stretch-badge"
                aria-hidden="false"
                [matBadge]="tableDataSource.filteredData.length | number:'1.0-0'"
                [matBadgeHidden]="!tableDataSource.isFiltered()"
                matBadgePosition="above before">search</mat-icon>
    </div>
    <button *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="Clear search"
            color="warn"
            (click)="applyFilter('')">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-mini-fab
            *ngIf="!disableSettings"
            matSuffix
            aria-label="Table settings"
            [matMenuTriggerFor]="tableSettings"
            color="accent"
            (click)="$event.stopPropagation()">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #tableSettings="matMenu">
      <button mat-menu-item (click)="clearAllFilters()">Clear all filters</button>
      <button mat-menu-item [matMenuTriggerFor]="toggleColumnMenu" [disabled]="!columns.length">Display columns</button>
      <mat-menu #toggleColumnMenu="matMenu">
        <button mat-menu-item
                (click)="toggleColumnVisibility(col); $event.stopPropagation();"
                class="flex-row flex-align-spacebetween-center gap-10"
                aria-label="Toggle column visibility"
                *ngFor="let col of columns">
          <mat-icon [color]="col.hide ? 'warn' : 'accent'">
            {{col.hide ? 'check_box_outline_blank' : 'check_box'}}
          </mat-icon>
          <div>{{col.title}}</div>
        </button>
      </mat-menu>
      <button mat-menu-item (click)="exportData()" [disabled]="!tableDataSource.data.length">Export to .csv</button>
      <button mat-menu-item (click)="reset()">Reset table</button>
    </mat-menu>
  </mat-form-field>

  <div *ngIf="isLoading"
       [style.height]="'calc(100% - ' + loadingOverlayOffset +  'px)'"
       [style.top.px]="loadingOverlayOffset"
       class="absolute left-0 w-full z-10 flex justify-center items-center">
    <mat-spinner></mat-spinner>
  </div>

  <cdk-virtual-scroll-viewport tvsItemSize
                               #viewport
                               [headerEnabled]="!disableHeader"
                               [footerEnabled]="showFooter"
                               class="h-full mat-elevation-z2">
    <table mat-table
           [style.min-width.px]="tableMinWidth"
           matSort
           [matSortDisabled]="disableSorting"
           (matSortChange)="onSortChange($event)"
           [dataSource]="tableDataSource"
           cdkDropList
           cdkDropListOrientation="horizontal"
           cdkDropListLockAxis="x"
           cdkDropListAutoScrollDisabled
           (cdkDropListDropped)="drop($event)"
           class="w-full">

      <ng-container *ngIf="!disableHeader">
        <tr mat-header-row *matHeaderRowDef="allDisplayedColumns; sticky: true"></tr>
      </ng-container>

      <tr mat-row
          *matRowDef="let row; columns: allDisplayedColumns; let i = index;"
          [style.cursor]="enableRowClick ? 'pointer' : 'auto'"
          [matTooltip]="rowTooltip ? rowTooltip(row) : ''"
          matTooltipShowDelay="1000"
          matTooltipPositionAtOrigin
          [class.even]="dataIndex(i) % 2 === 0"
          [style.background]="rowBackground ? rowBackground(row) : ''"
          [style.color]="rowColor ? rowColor(row) : ''"
          (click)="onRowClick(row, i)"></tr>

      <tr *matNoDataRow class="mat-row mat-app-background">
        <td class="mat-cell"
            [style.height.px]="viewport.elementRef.nativeElement.clientHeight - 58"
            [attr.colspan]="allDisplayedColumns.length">
          <div class="flex justify-center">
            <h2 *ngIf="data && !isLoading; else loadingData">No {{entityName}} found</h2>
            <ng-template #loadingData>
              <mat-spinner *ngIf="!isLoading"></mat-spinner>
            </ng-template>
          </div>
        </td>
      </tr>

      <ng-container *ngIf="showFooter">
        <tr mat-footer-row *matFooterRowDef="allDisplayedColumns; sticky: true"></tr>
      </ng-container>

      <ng-container [matColumnDef]="selectColumnDef" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <div class="flex w-min flex-col justify-center items-center">
            <div *ngIf="!selection.isEmpty()" class="selection-count">
              {{selection.selected.length | number:'1.0-0'}}
            </div>
            <mat-checkbox (change)="masterToggle($event.checked)"
                          *ngIf="!disableSelectAll"
                          [disabled]="!tableDataSource.filteredData.length"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </div>
        </th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-checkbox (click)="rowSelectionToggle($event, i)"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container *ngFor="let column of columns"
                    [matColumnDef]="column.field"
                    [sticky]="column.sticky === 'start'"
                    [stickyEnd]="column.sticky === 'end'">
        <th mat-header-cell
            cdkDrag
            #headerCell="cdkDrag"
            (mouseenter)="mouseoverColumnHeader = column"
            (mouseleave)="mouseoverColumnHeader = null"
            [cdkDragDisabled]="!column.draggable"
            cdkDragBoundary=".cdk-drop-list"
            [ngStyle]="column.styles"
            *matHeaderCellDef>
          <div *ngIf="column.displayTitle" class="flex justify-{{column.align}} items-center" mat-sort-header [disabled]="column.sort === false">
            {{column.title}}
          </div>
          <mat-icon *ngIf="column.draggable"
                    cdkDragHandle
                    class="absolute top-0 {{column.align === 'end' ? 'left' : 'right'}}-0"
                    (mousedown)="dragHeaderPosition = headerCell.getRootElement().style.top"
                    [style.display]="column === mouseoverColumnHeader ? 'inline-block' : 'none'"
                    (click)="$event.stopPropagation()">drag_handle</mat-icon>
          <vs-table-column-filter *ngIf="column.filter"
                                  (filterChange)="columnFilterChange($event)"
                                  [column]="column"
                                  [filteredData]="tableDataSource.filteredData"
                                  [activeFilter]="tableDataSource.columnFilters[column.field]"
                                  [data]="tableDataSource.data"
                                  [hide]="column !== mouseoverColumnHeader"></vs-table-column-filter>
        </th>
        <td mat-cell *matCellDef="let row; let i = index;" [ngStyle]="column.styles">
          <vs-table-cell [column]="column"
                         [row]="row"
                         [rowHeight]="itemSize"
                         (expandChange)="toggleExpand(row, column, i)"></vs-table-cell>
        </td>
        <td mat-footer-cell *matFooterCellDef [ngStyle]="column.styles">
          <div class="flex justify-{{column.align}} items-center" [ngSwitch]="column.dataType">
            <vs-table-currency-cell *ngSwitchCase="columnTypes.Currency"
                                    [column]="column"
                                    [value]="columnSums[column.field]"
                                    [compareValue]="column.compareField && columnSums[column.compareField]"></vs-table-currency-cell>
            <div *ngSwitchCase="columnTypes.Number">
              {{columnSums[column.field] | number:column.format}}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="menuColumnDef" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button
                  *ngIf="tableMenuItems"
                  [disabled]="!selection.selected.length"
                  aria-label="Multiple rows menu"
                  [matMenuTriggerFor]="tableMenu">
            <mat-icon>drag_indicator</mat-icon>
          </button>
          <mat-menu #tableMenu="matMenu">
            <div *ngFor="let item of tableMenuItems">
              <button
                *ngIf="!item.hide || !item.hide(selection.selected)"
                mat-menu-item
                [disabled]="!!(item.disable && item.disable(selection.selected))"
                (click)="item.callback(selection.selected)">
                <span>{{item.name(selection.selected)}}</span>
              </button>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button
                  *ngIf="rowMenuItems"
                  aria-label="Row menu"
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="rowMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #rowMenu="matMenu">
            <div *ngFor="let item of rowMenuItems">
              <button
                *ngIf="!item.hide || !item.hide(row)"
                mat-menu-item
                [disabled]="!!(item.disable && item.disable(row))"
                (click)="item.callback(row)">
                <span>{{item.name(row)}}</span>
              </button>
            </div>
          </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

    </table>
  </cdk-virtual-scroll-viewport>
</div>
