import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {filter, switchMap, first, map, tap} from 'rxjs/operators';
import {AUTH_CONFIG} from '../consts';
import {Store} from '@ngrx/store';
import {selectAuthLoading, selectIsAuthenticated} from "../store/auth.selectors";

/**
 * CanActivate function for any route that requires user authentication or requires user to not be authenticated.
 * @param auth - True if the user must be authenticated. False if user must not be authenticated.
 */
export const authGuard: (auth: boolean) => CanActivateFn = (auth) => (route, state) => {
  const router = inject(Router);
  const store = inject(Store);
  const config = inject(AUTH_CONFIG);
  return store.select(selectAuthLoading)
    .pipe(
      filter((loading) => !loading),
      switchMap(() => store.select(selectIsAuthenticated)),
      first(),
      map((isAuthenticated) => auth ? isAuthenticated : !isAuthenticated),
      tap((canActivate) => {
        if (!canActivate) {
          const queryParams = auth && state.url !== '/' ? { redirectUrl: state.url } : { };
          router.navigate([auth ? config.loginRoute : '/'], { queryParams });
        }
      })
    );
};
