import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {BaseField, DynamicFormModule, SelectField} from "dynamic-form";
import {FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {AsyncPipe} from "@angular/common";
import {Store} from "@ngrx/store";
import {EnumsActions} from "../../core/store/enums/action-types";
import {Overlay, OverlayModule} from "@angular/cdk/overlay";
import {MAT_SELECT_SCROLL_STRATEGY} from "@angular/material/select";
import {collectionToKeyValue$, isTruthy} from "caig-utils";
import {selectTags} from "../../core/store/enums/enums.selectors";
import {BehaviorSubject, filter, combineLatest, map} from "rxjs";
import {selectSessionSettlements} from "../../core/store/core/core.selectors";
import {coerceStringArray} from "@angular/cdk/coercion";

@Component({
  selector: 'lib-employees-filter',
  standalone: true,
  imports: [
    AsyncPipe,
    OverlayModule,
    MatDialogModule,
    MatButtonModule,
    DynamicFormModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.noop(),
      deps: [Overlay]
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './employees-filter.component.html',
  styleUrls: ['./employees-filter.component.css']
})
export class EmployeesFilterComponent implements OnInit {
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private openOnlySettlements$ = new BehaviorSubject<boolean>(false);

  form = new FormGroup({});
  model$ = this.route.queryParams.pipe(
    map((queryParams) => ({
      ...queryParams,
      tag: coerceStringArray(queryParams['tag']),
      allSettlementIds: coerceStringArray(queryParams['allSettlementIds']).map(Number),
    }))
  );
  fields: BaseField<any>[][] = [
    [
      new SelectField({
        name: 'allSettlementIds',
        label: 'Cases',
        options: collectionToKeyValue$(
          combineLatest([
            this.store.select(selectSessionSettlements),
            this.openOnlySettlements$,
          ]).pipe(
            map(([settlements, openOnly]) => {
              if (!settlements || !openOnly) {
                return settlements;
              }
              return settlements.filter((s) => s.isOpen);
            }),
          ), 'code', 'id',
        ),
        multiple: true,
        optionFilter: {
          label: 'Open Only',
          onChange: (checked) => this.openOnlySettlements$.next(checked),
        }
      }),
      new SelectField({
        name: 'tag',
        label: 'Tags',
        options: this.store.select(selectTags).pipe(filter(isTruthy)),
        deselect: true,
        multiple: true,
      })
    ],
  ];

  ngOnInit() {
    this.store.dispatch(EnumsActions.loadEnum({enumType: 'tags'}));
  }
}
