import {Injectable} from "@angular/core";
import {VsTableCacheState} from "./types";

@Injectable({providedIn: 'root'})
export class CacheService<T> {
  private readonly cache: Set<string> = new Set();
  get(tableKey: string): VsTableCacheState<T> | null {
    const cachedData = localStorage.getItem(tableKey);
    return cachedData ? JSON.parse(cachedData) : null;
  }
  update(tableKey: string, state: VsTableCacheState<T>): void {
    const stateString = JSON.stringify(state);
    localStorage.setItem(tableKey, stateString);
    this.cache.add(tableKey);
  }
  delete(tableKey: string, propKeys?: Array<keyof VsTableCacheState<T>>): void {
    if (propKeys?.length) {
      const state = this.get(tableKey);
      if (state) {
        propKeys.forEach((propKey) => delete state[propKey]);
        if (Object.keys(state).length) {
          this.update(tableKey, state);
        } else {
          this.delete(tableKey);
        }
      }
    } else {
      localStorage.removeItem(tableKey);
      this.cache.delete(tableKey);
    }
  }
}
