import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {ActivatedRoute, Router} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@Component({
  selector: 'lib-entity-iterator',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    NgIf,
    MatProgressBarModule,
  ],
  templateUrl: './entity-iterator.component.html',
  styleUrls: ['./entity-iterator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityIteratorComponent<T extends {id: string | number}> {
  private router = inject(Router);
  route = inject(ActivatedRoute);

  @Input({required: true}) entityIndex: number | null = null;
  @Input({required: true}) entities: T[] | null = null;
  @Input({required: true}) entityUrl: string = '';
  @Input() isLoading: boolean | null = false;

  cycleTo(entities: T[], index: number) {
    this.router.navigate([`/${this.entityUrl}`, entities[index].id], {queryParamsHandling: 'preserve'});
  }
}
