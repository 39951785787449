<div class="flex flex-col h-full" *ngIf="route.queryParams | async as queryParams">
  <div class="flex justify-between items-center p-2">
    <div class="p-2 mr-4">
      <h1 class="m-0">Employees</h1>
    </div>
    <div class="flex overflow-x-auto overflow-y-hidden gap-4">
      <div class="min-w-[150px]" *ngFor="let btn of toolbarButtons">
        <a mat-stroked-button
           [color]="btn.color || 'primary'"
           [disabled]="!!btn.disabled"
           (click)="btn.callback ? btn.callback() : null"
           queryParamsHandling="preserve"
           [routerLink]="btn.routerLink">
          <mat-icon *ngIf="btn.icon">{{btn.icon}}</mat-icon>
          <div>{{btn.label}}</div>
        </a>
      </div>
    </div>
  </div>
  <div class="flex items-center p-4 gap-2" *ngIf="queryParams | keyvalue as filtersList">
    <button mat-fab
            extended
            (click)="openFilters()"
            [color]="filtersList.length ? 'primary' : undefined"
            matBadgeColor="accent"
            [matBadge]="filtersList.length || null">
      <mat-icon>manage_search</mat-icon>Advanced Search
    </button>
    <button mat-mini-fab color="warn" (click)="filterEmployees({})" [disabled]="!filtersList.length">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="flex-grow ml-2">
      <mat-button-toggle-group [value]="viewMode()" (change)="viewMode.set($event.value)" class="w-full" name="viewMode" aria-label="View Mode">
        <mat-button-toggle class="w-full" [value]="mode.value" *ngFor="let mode of viewModes | keyvalue:compareViewModes">
          {{mode.key}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="flex-grow mt-10">
    <vs-table
      #table="vsTable"
      rowSelection
      [rowMenuItems]="rowMenuItems"
      [tableMenuItems]="tableMenuItems"
      [rowColor]="settlementIdPainter$ | async"
      [rowTooltip]="rowTooltip$ | async"
      (filterSortChange)="filterSortChange()"
      enableRowClick
      (rowClick)="viewEmployee($event.row, table)"
      cacheKey="employees-list-{{viewMode()}}"
      [data]="entities$ | async"
      [isLoading]="isLoading$ | async"
      [columns]="columns()">
    </vs-table>
  </div>
</div>
