<div class="flex-column">
  <div class="flex-row flex-align-end-center">
    <div class="checkbox-container">
      <mat-checkbox *ngIf="config.optionFilter"
                    labelPosition="before"
                    (change)="config.optionFilter.onChange($event.checked); control.reset()">
        {{config.optionFilter.label}}
      </mat-checkbox>
      <mat-checkbox
        *ngIf="config.multiple && !config.disableSelectAll"
        [disabled]="!select.options?.length"
        [indeterminate]="select.options && !!control.value?.length && control.value.length < select.options.length"
        [checked]="control.value?.length === select.options?.length"
        (change)="toggleSelectAll($event.checked, select)"
        labelPosition="before">
        Select All
      </mat-checkbox>
    </div>
  </div>
  <mat-form-field [formGroup]="form"
                  [appearance]="config.appearance"
                  [subscriptSizing]="config.subscriptSizing"
                  [color]="config.color">
    <mat-label>{{config.label}}</mat-label>
    <mat-select [formControlName]="config.name"
                #select="matSelect"
                [required]="config.required"
                [multiple]="config.multiple">
      <mat-select-trigger *ngIf="config.multiple">
        {{(firstSelectedOption$ | async)?.key}}
        <span *ngIf="(control.value?.length || 0) > 1" class="multi-select-text">
        (+{{(control.value?.length || 0) - 1}} {{control.value?.length === 2 ? 'other' : 'others'}})
      </span>
      </mat-select-trigger>
      <mat-option *ngIf="config.deselect && !config.multiple"></mat-option>
      <mat-option *ngFor="let option of options$ | async"
                  [disabled]="!!config.optionDisabled && config.optionDisabled(option, form)"
                  [style.color]="config.optionColor ? config.optionColor(option, form) : ''"
                  [value]="option.value">{{option.key}}</mat-option>
    </mat-select>
    <mat-progress-bar *ngIf="!(options$ | async)" mode="indeterminate" [color]="config.color"></mat-progress-bar>
    <mat-hint *ngIf="config.hint as hint" [align]="hint.align || 'start'">{{hint.message}}</mat-hint>
    <mat-error *ngIf="control.hasError('required')">{{config.label}} is required.</mat-error>
  </mat-form-field>
</div>
