import {NgModule, ModuleWithProviders} from '@angular/core';
import { LoginComponent } from './component/login/login.component';
import {AUTH_CONFIG} from './consts';
import {AuthConfig} from './interfaces';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthService} from './service/auth.service';
import {CommonModule} from '@angular/common';
import {AuthMaterialModule} from './auth-material.module';
import {ReactiveFormsModule} from '@angular/forms';
import { LoginErrorComponent } from './component/login-error/login-error.component';
import {RouterLink} from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
  MsalModule, MsalService
} from "@azure/msal-angular";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory
} from "./msal-config";
import {AuthInterceptor} from "./interceptor/auth.interceptor";
import {CustomMsalInterceptor} from "./interceptor/custom-msal.interceptor";

@NgModule({
  imports: [
    MsalModule,
    AuthMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  declarations: [
    LoginComponent,
    LoginErrorComponent,
  ],
  exports: [
    LoginComponent,
  ]
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomMsalInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: AUTH_CONFIG,
          useValue: config,
        }
      ],
    }
  }
}
