import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseField, DynamicFormModule, SelectField} from 'dynamic-form';
import {DecimalPipe, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {Employee} from "../../interface/employees.interface";
import {EmployeeEntityService} from "../../service/entity-data/employee-entity.service";
import {collectionToKeyValue$} from "caig-utils";
import {SettlementUser, SettlementUserEntityService} from "../../service/entity-data/settlement-user-entity.service";
import {map, tap} from "rxjs";
import {MAT_SELECT_SCROLL_STRATEGY} from "@angular/material/select";
import {Overlay} from "@angular/cdk/overlay";

@Component({
  selector: 'app-assign-user',
  templateUrl: './assign-user.component.html',
  standalone: true,
  imports: [
    NgIf,
    DecimalPipe,
    DynamicFormModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.noop(),
      deps: [Overlay]
    },
  ],
})
export class AssignUserComponent {
  private users$ = this.userDataService.entities$.pipe(
    tap((users) => this._users = users),
    map((users) => [{id: 0, name: '< None >'}, ...users]),
  );
  public userId: number | undefined;
  public changed = false;
  public fields: BaseField<any>[][] = [
    [
      new SelectField({
        name: 'userId',
        label: 'User',
        options: collectionToKeyValue$(this.users$, 'name', 'id'),
        onChange: (value) => {
          this.userId = value as number;
          this.changed = true;
        },
      })
    ]
  ];
  public isProcessing = false;
  private _users: SettlementUser[] | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Employee[],
    private dialogRef: MatDialogRef<AssignUserComponent>,
    private userDataService: SettlementUserEntityService,
    private dataService: EmployeeEntityService,
  ) {
    this.userDataService.loadIfNeeded();
  }
  public save(): void {
    this.isProcessing = true;
    const userId = this.userId || 0;
    const username = this._users?.find((u) => u.id === userId)?.username;
    const onError = () => this.isProcessing = false;
    if (this.data.length === 1) {
      const updated = { ...this.data[0], userId, username };
      this.dataService.update(updated)
        .subscribe({
          next: () => this.dialogRef.close(true),
          error: onError,
        });
    } else {
      const ids = this.data.map((e) => e.id);
      const value = { userId, username };
      this.dataService.bulkPatch({ids, value}).subscribe({
        next: () => {
          this.dataService.updateManyInCache(this.data.map((e) => ({...e, ...value})));
          this.dialogRef.close(true);
        },
        error: onError,
      });
    }
  }
}
