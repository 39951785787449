import {Injectable, inject} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {first, tap, switchMap} from 'rxjs/operators';
import {AuthToken} from '../interfaces';
import {AUTH_CONFIG} from '../consts';
import {selectAuthToken} from "../store/auth.selectors";
import {AuthActions} from "../store/action-types";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private store = inject(Store);
  private config = inject(AUTH_CONFIG);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<AuthToken>> {
    return this.store.select(selectAuthToken).pipe(
      first(),
      tap((token) => {
        if (this.needsRefresh(req, token)) {
          this.store.dispatch(AuthActions.refreshToken());
        }
      }),
      switchMap((token) => this.handleRequest(req, next, token)),
    );
  }
  private needsRefresh(req: HttpRequest<any>, token: AuthToken | null): boolean {
    return !!(
      token &&
      this.config.refreshToken &&
      !req.url.endsWith(this.config.refreshToken.apiUrl) &&
      this.config.refreshToken.refreshWhen(token)
    );
  }
  private handleRequest(req: HttpRequest<any>, next: HttpHandler, payload: AuthToken | null) {
    const token = payload?.token;
    const cloned = req.clone({
      headers: token ? req.headers.set('Authorization', 'Bearer ' + token) : req.headers
    });
    return next.handle(cloned);
  }
}
