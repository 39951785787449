import {Employee} from "../../interface/employees.interface";

export const EMPLOYEE_BATCH_CACHE_KEY = 'employeeBatch';

export interface EmployeeState {
  batchId: string | null;
  employees: Employee[] | null;
  loading: boolean;
}

export const initialEmployeeState: EmployeeState = {
  batchId: localStorage.getItem(EMPLOYEE_BATCH_CACHE_KEY),
  employees: null,
  loading: false,
};
