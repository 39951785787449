import {createFeatureSelector, createSelector} from "@ngrx/store";
import {EmployeeState} from "./employee.state";

export const employeeFeatureKey = 'employee';
export const selectEmployeeState = createFeatureSelector<EmployeeState>(employeeFeatureKey);
export const selectEmployeeBatchId = createSelector(
  selectEmployeeState,
  (state) => state.batchId
);

export const selectEmployeeBatch = createSelector(
  selectEmployeeState,
  (state) => state.employees
);

export const selectIsEmployeeBatchLoading = createSelector(
  selectEmployeeState,
  (state) => state.loading,
);
