import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EnumsState} from "../store/enums/enums.state";
import {Observable} from "rxjs";
import {EmployeeStatus, ParticipationStatus} from "../interface/employees.interface";
import {CallType} from "../enum/call-types.enums";
import {UserRole} from "../interface/session.interface";
import {AppEventType} from "../interface/events.interface";
import {CORE_CONFIG} from "../consts/injection-tokens";
import {AppPortal} from "../enum/session.enums";

@Injectable({providedIn: 'root'})
export class EnumDataService implements IEnumDataService {
  private http = inject(HttpClient);
  private config = inject(CORE_CONFIG);

  employeeStatus() {
    return this.http.get<EmployeeStatus[]>('/api/enum/employeeStatus');
  }

  allStates() {
    return this.states(false);
  }

  settlementStates() {
    return this.states(true);
  }

  eventTypesPortal() {
    let group: string | undefined;
    switch (this.config.portal) {
      case AppPortal.CallCenter:
        group = 'Call Project';
    }
    return this.eventTypes(group);
  }

  eventTypesEmail() {
    return this.eventTypes('email');
  }

  bueLocations() {
    return this.http.get<string[]>('/api/location');
  }

  bueLocals() {
    return this.http.get<string[]>('/api/location/local');
  }

  bueRegions() {
    return this.http.get<string[]>('/api/location/region');
  }

  callTypes(): Observable<CallType[]> {
    return this.http.get<CallType[]>('/api/enum/voicecalltype');
  }

  userRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>('/api/user/role');
  }

  participationStatuses(): Observable<ParticipationStatus[]> {
    return this.http.get<ParticipationStatus[]>('/api/enum/participationStatus');
  }

  tags() {
    return this.http.get<string[]>('/api/tag');
  }

  private states(inSettlement: boolean) {
    const params = { inSettlement };
    return this.http.get<string[]>('/api/enum/state', { params });
  }

  private eventTypes(group?: string) {
    const params = group ? { group } : undefined;
    return this.http.get<AppEventType[]>('/api/event/type', {params});
  }
}

type IEnumDataService = {
  [Property in keyof EnumsState]: () => Observable<any[]>;
}
