export function formatMonth(month?: number): string {
  if (month === undefined) {
    return '';
  }
  const months: {[key: number]: string} = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };
  return month === 0 ? 'Uncertain' : months[month];
}

export function formatYear(year?: number): string | number {
  if (year === undefined) {
    return '';
  }
  return year === 0 ? 'Uncertain' : year;
}

export const protectiveEquipmentOptions = [
  { key: 'a', value: 'Yes, it is required.' },
  { key: 'b', value: 'Yes, it is optional.' },
  { key: 'c', value: `No, and I don't want to.` },
  { key: 'd', value: 'No, but I have requested it and it was not provided.' },
];

export const hazardTrainingOptions = [
  { key: 'a', value: 'Yes, one time.' },
  { key: 'b', value: 'Yes, at least one time a year.' },
  { key: 'c', value: 'No' },
];

export interface Survey extends BaseSurvey {
  schemaId: number;
  estTime: string;
  locations: BaseSurvey[];
  url: string;
  isOpen: boolean;
}

export interface BaseSurvey {
  id: string;
  name: string;
  shortcut?: string;
  respondentCount: number;
  substitution?: {[key: string]: any}
}
