import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {combineLatest, first, firstValueFrom, map, startWith, switchMap} from "rxjs";
import {inject} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NotificationService} from "notification";
import {Params} from "@angular/router";
import {isTruthy} from "caig-utils";

export abstract class EntityStoreBaseService<T> extends EntityCollectionServiceBase<T> {
  protected http = inject(HttpClient);
  protected notification = inject(NotificationService);
  entitiesLoaded$ = this.loaded$.pipe(
    first(isTruthy),
    switchMap(() => this.entities$),
    startWith(null),
  );
  loadedOrLoading$ = combineLatest([this.loaded$, this.loading$]).pipe(
    map(([loaded, loading]) => loaded || loading),
  );
  constructor(entityName: string, serviceFactory: EntityCollectionServiceElementsFactory) {
    super(entityName, serviceFactory);
  }
  loadIfNeeded(params?: Params) {
    combineLatest([this.loaded$, this.loading$])
      .pipe(first(([loaded, loading]) => !loaded && !loading))
      .subscribe(() => {
        this.clearCache();
        if (params) {
          this.getWithQuery(params);
        } else {
          this.getAll();
        }
      });
  }
  async patch(entityId: string | number, payload: Partial<T>, self?: boolean): Promise<void> {
      this.setLoading(true);
      const url = `/api/${this.entityName}/${self ? 'self' : entityId}`;
      try {
          const patched = await firstValueFrom(this.http.patch<T>(url, payload));
          this.upsertOneInCache({...patched, id: entityId});
      }
      finally {
          this.setLoading(false);
      }
  }
  bulkPatch(payload: {ids: (number | string)[], value: Partial<T>}) {
    return this.http.patch<void>('/api/employee/bulk', payload);
  }
}
