<mat-toolbar *ngIf="user$ | async as user; else noUser" class="mat-elevation-z6" [matMenuTriggerFor]="userMenu">
  <div class="flex-1 flex-row flex-align-start-center gap-5">
    <mat-menu #userMenu="matMenu">
      <div *ngFor="let item of routeMenu">
        <a *ngIf="!item.roleId || item.roleId === user.roleId" mat-menu-item [routerLink]="item.route">
          <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
        </a>
      </div>
      <div *ngFor="let item of callbackMenu">
        <button *ngIf="!item.roleId || item.roleId === user.roleId" mat-menu-item (click)="item.callback()">
          <mat-icon>{{item.icon}}</mat-icon>{{item.label}}
        </button>
      </div>
    </mat-menu>
    <img *ngIf="user.avatar as img; else noImg"
         alt="User Profile"
         aria-label="User menu"
         [ngSrc]="img"
         width="50px"
         height="50px"
         class="avatar mat-elevation-z4" />
    <ng-template #noImg>
      <mat-icon>account_circle</mat-icon>
    </ng-template>
    <div class="greeting">{{user.name}}</div>
  </div>
</mat-toolbar>
<ng-template #noUser>
  <mat-toolbar>
    <button mat-button color="warn" class="w-full" (click)="logout()">Sign Out</button>
  </mat-toolbar>
</ng-template>
