<div class="flex flex-col h-full">
  <div class="flex justify-between items-center p-2">
    <div class="p-2 mr-4">
      <h1 class="m-0">Events</h1>
    </div>
  </div>
  <div class="flex justify-center items-center">
    <dynamic-form [fields]="formFields" [model]="formModel"></dynamic-form>
  </div>
  <div class="flex-grow">
    <vs-table
      #table
      [data]="events$ | async"
      [isLoading]="!table.data"
      [columns]="columns"
      cacheKey="events-list"
      enableRowClick
      (rowClick)="viewEmployee($event.row)"
    ></vs-table>
  </div>
</div>
