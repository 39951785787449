import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {coreConfig} from "./core-config";
import {MsalModule} from "@azure/msal-angular";
import {guardConfig, interceptorConfig, msalClient} from "./msal-config";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {DecryptInterceptor} from "./services/interceptors/decrypt.interceptor";
import {EntityStoreModule} from "./store/entity-store.module";
import {CoreModule} from "shared-modules";
import {QuillModule} from "ngx-quill";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule.forRoot(coreConfig),
    MsalModule.forRoot(msalClient, guardConfig, interceptorConfig),
    QuillModule.forRoot(),
    EntityStoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DecryptInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
