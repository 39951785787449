import {Component} from '@angular/core';
import {BaseFieldComponent} from "../base-field.component";
import {InputField} from "../../class/input-field";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'lib-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css'],
})
export class InputFieldComponent extends BaseFieldComponent<InputField> {
  removeNegativeSign(input: MatInput) {
    if (this.config.inputType === 'number' && this.config.disableNegative) {
      input.value = input.value.replace(/-/g, '');
      this.control.setValue(input.value, {emitEvent: false});
    }
  }
}
