import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatChipsModule} from "@angular/material/chips";
import {AsyncPipe, DecimalPipe, NgClass, NgIf} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CdkVirtualScrollViewport, ScrollingModule} from "@angular/cdk/scrolling";
import {MatIconModule} from "@angular/material/icon";
import {DynamicFormModule, InputField} from "dynamic-form";
import {EmployeeEntityService} from "../../../../core/service/entity-data/employee-entity.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {Employee} from "../../../../core/interface/employees.interface";
import {
  combineLatest,
  debounceTime,
  filter,
  map,
  of, startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import {FormDialogComponent, FormDialogData} from "../../../../core/component/form-dialog/form-dialog.component";
import {Validators} from "@angular/forms";
import {isTruthy} from "caig-utils";
import {EmailPreviewComponent, SendPayload} from "../email-preview/email-preview.component";
import {first, shareReplay} from "rxjs/operators";
import {EmailService} from "../../../../core/service/email.service";
import {LoadingService} from "../../../../core/service/loading.service";
import {NotificationService} from "notification";
import {SendEmailComponent} from "../send-email.component";
import {MatDividerModule} from "@angular/material/divider";

@Component({
  selector: 'lib-bulk-email',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    DynamicFormModule,
    MatCardModule,
    MatToolbarModule,
    MatChipsModule,
    EmailPreviewComponent,
    AsyncPipe,
    MatIconModule,
    MatTooltipModule,
    ScrollingModule,
    DecimalPipe,
    MatDividerModule
  ],
  templateUrl: './bulk-email.component.html',
  styleUrls: ['./bulk-email.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkEmailComponent extends SendEmailComponent implements OnInit, OnDestroy {
  private employeeService = inject(EmployeeEntityService);
  private emailService = inject(EmailService);
  private dialog = inject(MatDialog);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);
  private notifications = inject(NotificationService);

  private employeeIdChange$ = this.route.queryParamMap.pipe(
    map((params) => {
      const employeeId = params.get('employeeId');
      return employeeId ? parseInt(employeeId) : 0;
    }),
  );

  employees: Employee[] | undefined;
  employee: Employee | undefined;
  invalidEmployees: Employee[] | undefined;
  invalidEmployee: Employee | undefined;

  preview$ = combineLatest([
    this.templateForm.valueChanges,
    this.employeeIdChange$,
  ]).pipe(
    debounceTime(100),
    switchMap(([value, employeeId]) => {
      if (!this.employees) {
        return of(null);
      }
      const employee = this.employees[0];
      const {templateId, signatureId} = this.templateForm.getRawValue();
      if (!templateId || !signatureId) {
        return of(null);
      }
      return this.loadingService.load(this.emailService.renderEmail({templateId, signatureId, employeeId: employeeId || employee.id}))
        .pipe(startWith(null));
    }),
    shareReplay(1),
  );

  private onDestroy$ = new Subject<void>();

  override ngOnInit() {
    super.ngOnInit();

    this.route.data
      .pipe(
        map((data) => data['employees']),
        takeUntil(this.onDestroy$),
      )
      .subscribe((employees: Employee[]) => this.initializeEmployees(employees));
  }

  ngOnDestroy() {
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  scrollToNextInvalid(viewport: CdkVirtualScrollViewport): void {
    if (this.employees && this.invalidEmployees && this.invalidEmployees.length) {
      const index = this.employees.findIndex((e) => e === (this.invalidEmployees as Employee[])[0]);
      this.invalidEmployee = this.employees[index];
      viewport.scrollToIndex(index);
    }
  }

  getEmailForEmployee(employee: Employee): void {
    const data: FormDialogData = {
      title: `Enter an email address for ${employee.name}`,
      confirmText: 'Save',
      fields: [
        [
          new InputField({
            name: 'email',
            label: 'Email',
            validators: [ Validators.email ],
            required: true,
          })
        ]
      ],
    };
    this.dialog.open(FormDialogComponent, {data})
      .afterClosed()
      .pipe(
        filter(isTruthy),
        switchMap(({email}) => this.employeeService.update({...employee, email})
          .pipe(tap(() => employee.email = email))
        ),
      )
      .subscribe(() => {
        this.invalidEmployees = this.employees!.filter((e) => !e.email && !e.emailAlt);
        this.invalidEmployee = undefined;
        this.cd.detectChanges();
      });
  }

  sendEmail(event: SendPayload, viewport: CdkVirtualScrollViewport) {
    if (this.invalidEmployees?.length) {
      this.notifications.showSimpleMessage(`${this.invalidEmployees.length} ${this.invalidEmployees.length === 1 ? 'recipient has an invalid email address' : 'recipients have invalid email addresses'}`);
      this.scrollToNextInvalid(viewport);
      return;
    }

    this.settlement$
      .pipe(
        first(),
        switchMap((settlement) => this.loadingService.load(
          this.emailService.bulkSendEmail({
            ccAddress: settlement.adminCc,
            templateId: this.templateForm.value.templateId,
            signatureId: this.templateForm.value.signatureId,
            batchId: this.route.snapshot.params['batchId'],
            ...event,
            includeAltEmail: !!event.includeAltEmail,
          })
        ))
      )
      .subscribe(() => {
        this.notifications.showSimpleMessage(`Successfully sent email to ${this.employees?.length} employees`);
        this.router.navigateByUrl('/employees');
      });
  }

  private initializeEmployees(employees: Employee[]): void {
    this.employees = employees;
    this.employee = employees.find((e) => !!(e.email || e.emailAlt));
    this.invalidEmployees = employees.filter((e) => !e.email && !e.emailAlt);
  }
}
