import {EntityMetadataMap} from '@ngrx/data';
import {sortById} from "caig-utils";

export const entityMetadata: EntityMetadataMap = {
  Settlement: {},
  Employee: {
    sortComparer: sortById,
  },
  SettlementUser: {},
  User: {},
  Payroll: {},
  Secret: {},
};
