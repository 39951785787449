import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {EmployeeActions} from "./action-types";
import {EMPLOYEE_BATCH_CACHE_KEY} from "./employee.state";
import {EmployeeEntityService} from "../../service/entity-data/employee-entity.service";
import {LoadingService} from "../../service/loading.service";

@Injectable()
export class EmployeeEffects {
  private actions$ = inject(Actions);
  private dataService = inject(EmployeeEntityService);
  private loadingService = inject(LoadingService);

  createBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.createBatch),
      map(({employees}) => employees.map((e) => e.id)),
      switchMap((ids) => {
        return this.loadingService.load(this.dataService.createBatch(ids)).pipe(
          map(({batchId}) => EmployeeActions.batchCreated({batchId})),
          catchError(() => of(EmployeeActions.createBatchError()))
        );
      }),
    )
  );

  batchCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.batchCreated),
      tap(({batchId}) => localStorage.setItem(EMPLOYEE_BATCH_CACHE_KEY, batchId))
    ), {dispatch: false}
  );

  loadBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.loadBatch),
      switchMap(({batchId}) => this.loadingService.load(this.dataService.getBatch(batchId)).pipe(
        map((employees) => EmployeeActions.batchLoaded({employees})),
        catchError(() => of(EmployeeActions.batchLoadError()))
      )),
    )
  );

  clearBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.clearBatch),
      tap(() => localStorage.removeItem(EMPLOYEE_BATCH_CACHE_KEY))
    ), {dispatch: false}
  );
}
