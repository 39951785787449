import {inject, Injectable, NgZone} from "@angular/core";
import {LoadingOverlayComponent} from "../component/loading-overlay/loading-overlay.component";
import {ComponentPortal} from "@angular/cdk/portal";
import {
  debounceTime,
  finalize,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from "rxjs";
import {Overlay} from "@angular/cdk/overlay";

@Injectable({providedIn: 'root'})
export class LoadingService {
  private zone = inject(NgZone);
  private overlay = inject(Overlay);
  private overlayRef = this.overlay.create();
  private portal = new ComponentPortal(LoadingOverlayComponent);
  private attach$ = new Subject<boolean>();
  isLoading$ = merge(this.overlayRef.attachments(), this.overlayRef.detachments()).pipe(
    map(() => this.overlayRef.hasAttached()),
  );
  constructor() {
    this.attach$
      .pipe(debounceTime(200))
      .subscribe((attach) => {
        this.zone.run(() => {
          if (attach) {
            this.attach();
          } else {
            this.detach();
          }
        });
      });
  }
  load<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => this.attach$.next(true)),
      switchMap(() => obs$),
      finalize(() => this.attach$.next(false)),
    );
  }
  attach(): void {
    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
    }
  }
  detach(): void {
    this.overlayRef.detach();
  }
}
