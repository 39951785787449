import {ThemePalette} from "@angular/material/core";
import {FormFieldHint} from "../interfaces";
import {MatFormFieldAppearance, SubscriptSizing} from "@angular/material/form-field";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {FieldType, FlexJustify} from "../types";

export abstract class BaseField<T> {
  abstract type: FieldType;
  name: string;
  label: string;
  required: boolean;
  disabled: boolean;
  flex: number;
  flexJustify: FlexJustify;
  hide: boolean;
  appearance: MatFormFieldAppearance;
  color: ThemePalette;
  hint: FormFieldHint | undefined;
  defaultValue: T | undefined;
  onChange: ((value: T, form: FormGroup) => void) | undefined;
  validators: ValidatorFn[] | undefined;
  subscriptSizing: SubscriptSizing;
  id: string | undefined;

  constructor(config: BaseFieldConfig<T>) {
    this.name = config.name;
    this.label = config.label;
    this.required = !!config.required;
    this.disabled = !!config.disabled;
    this.flex = config.flex === undefined ? 1 : config.flex;
    this.flexJustify = config.flexJustify || 'flex-start';
    this.hide = !!config.hide;
    this.appearance = config.appearance || 'fill';
    this.color = config.color || 'primary';
    this.hint = config.hint;
    this.defaultValue = config.defaultValue;
    this.onChange = config.onChange;
    this.validators = config.validators;
    this.subscriptSizing = config.subscriptSizing || 'fixed';
    this.id = config.id;
  }
}

export interface BaseFieldConfig<T> {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  flex?: number;
  flexJustify?: FlexJustify;
  hide?: boolean;
  appearance?: MatFormFieldAppearance;
  color?: ThemePalette;
  hint?: FormFieldHint;
  defaultValue?: T;
  onChange?: (value: T, form: FormGroup) => void;
  validators?: ValidatorFn[];
  subscriptSizing?: SubscriptSizing;
  id?: string;
}
