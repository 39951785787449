<div class="flex flex-col g-10">
  <div class="flex flex-col justify-center items-center">
    <h1 class="mb-0">Files</h1>
    <h3>{{data.name}}</h3>
  </div>
  <div class="h-[400px] w-[450px]">
    <vs-table
      cacheKey="attached-files"
      disableToolbar
      [isLoading]="isLoading()"
      [data]="data.attachedFiles"
      [columns]="columns"
      [rowMenuItems]="rowMenuItems"
      [tableMenuItems]="tableMenuItems"
    ></vs-table>
  </div>
</div>
