import {Directive, inject} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {combineLatest, filter, map} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";

@Directive()
export abstract class ViewEmployeeBase {
  protected dataService = inject(EmployeeEntityService);
  protected bpObserver = inject(BreakpointObserver);
  protected route = inject(ActivatedRoute);
  protected entityId$ = this.route.params.pipe(
    map((params) => Number(params['id'])),
    filter((id) => !isNaN(id)),
    shareReplay(),
  );
  entity$ = combineLatest([this.entityId$, this.dataService.entities$]).pipe(
    map(([entityId, entities]) => entities.find((e) => e.id === entityId)),
    shareReplay(),
  );
  gridColumns$ = this.bpObserver.observe(Breakpoints.Handset).pipe(
    map(({matches}) => matches ? 1 : 2),
    shareReplay(),
  );
}
