import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {tap} from "rxjs/operators";
import {CoreActions} from "../core/action-types";
import {Router} from "@angular/router";

@Injectable()
export class EntityEffects {
  private actions$ = inject(Actions);
  // private config = inject(CORE_CONFIG);
  // private entityServices: EntityStoreBaseService<any>[] = this.config.resetEntitiesServices.map((type) => inject(type));
  private router = inject(Router);
  // private route = inject(ActivatedRoute);

  resetEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.resetEntities),
      tap(async ({redirect}) => {
        if (redirect) {
          await this.router.navigateByUrl(redirect);
        }
        window.location.reload();
      })
      // withLatestFrom(...this.entityServices.map((s) => s.loadedOrLoading$)),
      // tap(async ([action, ...reload]) => {
        // const params = this.route.snapshot.queryParams;
        // this.entityServices.forEach((service, index) => {
        //   service.clearCache();
        //   if (reload[index]) {
        //     service.getAll()
        //
        //     // @TODO - get with query
        //     console.log(params);
        //   }
        // });
        // if (action.redirect) {
        //   await this.router.navigateByUrl(action.redirect);
        // }
        // window.location.reload();
      // })
    ), {dispatch: false}
  );
}
