import {createAction, props} from "@ngrx/store";
import {Employee} from "../../interface/employees.interface";

export const createBatch = createAction(
  '[Employee List] Create Batch',
  props<{employees: Employee[]}>(),
);
export const batchCreated = createAction(
  '[Create Batch Effect] Batch Created',
  props<{batchId: string}>()
);

export const createBatchError = createAction('[Create Batch Effect] Create Batch Error');

export const loadBatch = createAction(
  '[Employee List] Load Batch',
  props<{batchId: string}>(),
);

export const batchLoadError = createAction('[Load Batch Effect] Batch Load Error');

export const batchLoaded = createAction(
  '[Load Batch Effect] Batch Loaded',
  props<{employees: Employee[]}>(),
);

export const clearBatch = createAction('[Employee List] Clear Batch');
