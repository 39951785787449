import {Injectable} from "@angular/core";
import {EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {EntityStoreBaseService} from "./entity-store-base.service";
import {Employee} from "../../interface/employees.interface";
import {map, Observable, tap} from "rxjs";
import {openFile} from "caig-utils";
import {Params} from "@angular/router";

@Injectable({providedIn: 'root'})
export class EmployeeEntityService extends EntityStoreBaseService<Employee> {
  constructor(serviceFactory: EntityCollectionServiceElementsFactory) {
    super('Employee', serviceFactory);
  }

  decrypt(employeeId: number, property: string): Observable<{[property: string]: string}> {
    return this.http.get<{[property: string]: string}>(`/api/employee/${employeeId}/${property}`);
  }

  createBatch(employeeIds: number[]): Observable<{ batchId: string }> {
    return this.http.post<{batchId: string}>('api/employee/batch', employeeIds);
  }

  getBatch(batchId: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(`api/employee/batch/${batchId}`);
  }

  getSignedReleaseUrl(signedReleaseId: string) {
    return this.http.get(`/api/employee/signedRelease/${signedReleaseId}`, { responseType: 'blob' })
      .pipe(map((blob) => URL.createObjectURL(blob)));
  }

  exportData(params?: Params) {
    return this.http.get(`/api/report/allEmployeesWithEventsCsv`, {responseType: 'blob', params})
      .pipe(tap((data) => openFile(data, 'Employees with Events')));
  }
}
