import {createReducer, on} from '@ngrx/store';
import {initialAuthState} from './auth.state';
import {AuthActions} from './action-types';
import {TokenStorageError} from '../enums';

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.checkAuth, (state) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.checkAuthComplete, (state, { authenticated, token }) => ({
    ...state,
    authenticated,
    token,
    loading: false
  })),
  on(AuthActions.loginWithCredentials, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(AuthActions.loginWithCredentialsSuccess, (state, { token, password }) => ({
    ...state,
    token,
    authenticated: true,
    loading: false,
    error: null,
    loginPassword: password || null,
  })),
  on(AuthActions.loginWithCredentialsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(AuthActions.loginWithMsal, (state) => ({
    ...state,
    loading: true,
    error: null
  })),
  on(AuthActions.loginWithMsalSuccess, (state) => ({
    ...state,
    authenticated: true,
    loading: false,
    error: null
  })),
  on(AuthActions.loginWithMsalFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  })),
  on(AuthActions.logout, (state, { expiredSession }) => ({
    ...initialAuthState,
    error: expiredSession ? TokenStorageError.Expired : null,
  })),
  on(AuthActions.refreshToken, (state) => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.refreshTokenSuccess, (state, { token }) => ({
    ...state,
    loading: false,
    token,
  })),
  on(AuthActions.refreshTokenFailure, (state) => ({
    ...state,
    loading: false,
  }))
);
