import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy, OnInit, Output,
  ViewChild
} from '@angular/core';
import {
  VsTableCacheState,
  VsTableColumn,
  VsTableColumnDataType,
  VsTableExpandedDetailConfig, VsTableFilterSortChange, VsTableFilterSortState,
  VsTableMenuItem, VsTableRowClick
} from "../types";
import {DataColumn, exportToCSV, isEmpty, SimpleChangesTyped} from "caig-utils";
import {coerceBooleanProperty} from "@angular/cdk/coercion";
import {MatSort, Sort} from "@angular/material/sort";
import {SelectionChange, SelectionModel} from "@angular/cdk/collections";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {VsTableDataSource} from "../vs-table-data-source";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {calcProp} from "../util";
import {CacheService} from "../cache.service";
import {skipWhile, Subject, takeUntil} from "rxjs";
import {take} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {ExpandedDialogComponent} from "../expanded-dialog/expanded-dialog.component";
import {VsTableColumnFilter} from "../column-filter";
import {NotificationService} from "notification";
import {CurrencyColumn} from "../column-types/currency-column";
import {round} from "lodash";
import {CalculateColumn} from "../column-types/calculate-column";

@Component({
  selector: 'vs-table',
  templateUrl: './vs-table.component.html',
  styleUrls: ['./vs-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'vsTable',
})
export class VsTableComponent<T = any> implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input({required: true}) columns!: VsTableColumn<T>[];
  @Input({required: true}) data!: T[] | null;
  @Input({transform: coerceBooleanProperty}) disableHeader: boolean = false;
  @Input({transform: coerceBooleanProperty}) disableToolbar: boolean = false;
  @Input({transform: coerceBooleanProperty}) disableSettings: boolean = false;
  @Input({transform: coerceBooleanProperty}) rowSelection: boolean = false;
  @Input({transform: coerceBooleanProperty}) disableSelectAll: boolean = false;
  @Input({transform: coerceBooleanProperty}) enableRowClick: boolean = false;
  @Input({transform: coerceBooleanProperty}) disableSorting: boolean = false;
  @Input({transform: coerceBooleanProperty}) isLoading: boolean = false;
  @Input() headerHeight: number = 56;
  @Input() footerHeight: number = 52;
  @Input() itemSize: number = 52;
  @Input() entityName: string = 'records';
  @Input() defaultFilter: string = '';
  @Input() defaultSort: Sort | undefined;
  @Input() defaultSelectionFn: ((row: T) => boolean) | null | undefined;
  @Input() rowMenuItems: VsTableMenuItem<T>[] | null = null;
  @Input() tableMenuItems: VsTableMenuItem<T[]>[] | null = null;
  @Input() cacheKey: string | null | undefined;
  @Input() rowName: ((row: T) => string) | null | undefined;
  @Input() startAtIndex: number = 0;
  @Input() rowTooltip: ((row: T) => string) | null | undefined;
  @Input() rowBackground: ((row: T) => string) | null | undefined;
  @Input() rowColor: ((row: T) => string) | null | undefined;
  @Output() filterSortChange = new EventEmitter<VsTableFilterSortChange>();
  @Output() rowClick = new EventEmitter<VsTableRowClick<T>>();
  @Output() selectionChange = new EventEmitter<SelectionChange<T>>();
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  private cacheService = inject(CacheService);

  private dialog = inject(MatDialog);
  private notifications = inject(NotificationService);
  private onDestroy$ = new Subject<void>();
  private skipSortEvent: boolean = false;
  readonly tableDataSource = new VsTableDataSource<T>([]);

  readonly selection = new SelectionModel<T>(true, []);
  readonly selectColumnDef: string = 'vs-table-select';
  readonly menuColumnDef: string = 'vs-table-menu';
  readonly columnTypes = VsTableColumnDataType;
  columnSums: {[field: string]: number} = {};
  allDisplayedColumns: string[] = [];
  unsortedColumns: VsTableColumn<T>[] = [];
  mouseoverColumnHeader: VsTableColumn<T> | null = null; // Show/hide drag handle and column filter icons
  dragHeaderPosition: string | null = null; // Re-position sticky header cell after drag and drop
  showFooter: boolean = false;

  get tableMinWidth(): number {
    const selectWidth = this.rowSelection ? 42 : 0;
    const menuWidth = this.rowMenuItems || this.tableMenuItems ? 50 : 0;
    const columnsWidth = this.visibleColumns.reduce((prev, curr) => {
      let minWidth = 130; // Default minWidth per column
      if (curr.styles) {
        // Overwrite minWidth if width properties are present
        const minWidthValue = curr.styles['min-width'] || curr.styles['minWidth'];
        const maxWidthValue = curr.styles['max-width'] || curr.styles['maxWidth'];
        if (minWidthValue) {
          const parsedMinWidth = typeof minWidthValue === 'number' ? minWidthValue : parseInt(minWidthValue, 10);
          if (!isNaN(parsedMinWidth)) {
            minWidth = parsedMinWidth;
          }
        } else if (maxWidthValue) {
          const parsedMaxWidth = typeof maxWidthValue === 'number' ? maxWidthValue : parseInt(maxWidthValue, 10);
          if (!isNaN(parsedMaxWidth) && parsedMaxWidth < minWidth) {
            minWidth = parsedMaxWidth;
          }
        }
      }
      return prev + minWidth;
    }, 0);
    return selectWidth + menuWidth + columnsWidth;
  }

  get isFilteredOrSorted(): boolean {
    return this.tableDataSource.isFiltered() || !!this.sort.direction;
  }

  get filterSortState(): VsTableFilterSortState<T> {
    return {
      filter: this.tableDataSource.filter,
      sort: {active: this.sort.active, direction: this.sort.direction},
      columnFilters: this.tableDataSource.columnFilters,
    };
  }

  get filteredSortedData() {
    return this.tableDataSource.sortData(
      this.tableDataSource.filteredData,
      this.sort,
    );
  }

  get loadingOverlayOffset(): number {
    return 56 * (this.disableToolbar ? 1 : 2);
  }

  dataIndex(viewportIndex: number) {
    return this.viewport.getRenderedRange().start + viewportIndex;
  }

  private get displayedColumns(): Extract<keyof T, string>[] {
    return this.allDisplayedColumns
      .filter((c) => c !== this.selectColumnDef && c !== this.menuColumnDef) as Extract<keyof T, string>[];
  }

  private get visibleColumns(): VsTableColumn<T>[] {
    return this.columns.filter((c) => !c.hide);
  }

  private get calculateColumns(): CalculateColumn<T>[] {
    return this.columns.filter((c): c is CalculateColumn<T> => !!c.calculate);
  }

  ngOnInit() {
    if (this.defaultFilter) {
      this.tableDataSource.filter = this.defaultFilter;
    }

    this.selection.changed
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => this.selectionChange.emit(event));
  }

  ngAfterViewInit() {
    this.tableDataSource.sort = this.sort;
    this.applyCache();
  }

  ngOnChanges(changes: SimpleChangesTyped<this>) {
    if (changes.columns) {
      this.unsortedColumns = this.columns.map((c) => ({...c}));
    }
    if (changes.columns || changes.rowSelection || changes.rowMenuItems || changes.tableMenuItems) {
      this.initDisplayColumns();
    }
    if ((changes.columns || changes.data) && this.data) {
      const calcCols = this.calculateColumns;
      this.tableDataSource.data = calcCols.length ? this.data.map((row: any) => {
        const calcRow = { ...row };
        calcCols.forEach(setCalcProp(calcRow));
        return calcRow;
      }) : this.data.slice();
      this.calculateColumnSums();
    }
    if (this.defaultSelectionFn && (changes.rowSelection || changes.data) && this.data && this.rowSelection) {
      const selectBy = this.defaultSelectionFn;
      const selected = this.tableDataSource.data.filter(selectBy);
      this.selection.select(...selected);
    }
  }

  ngOnDestroy() {
    this.saveCache(['scrollTop']);
    this.onDestroy$.next(void 0);
    this.onDestroy$.complete();
  }

  applyFilter(filterValue: string, emitEvent: boolean = true) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    this.saveCache(['filter']);
    this.calculateColumnSums();
    if (emitEvent) {
      this.onFilterOrSort();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.filteredData.length;
    return numSelected >= numRows;
  }

  masterToggle(checked: boolean) {
    checked ?
      this.selection.select(...this.tableDataSource.filteredData) :
      this.selection.clear();
  }

  onSortChange(event: Sort) {
    this.saveCache(['sort']);
    if (this.skipSortEvent) {
      this.skipSortEvent = false;
      return;
    }
    this.onFilterOrSort();
  }

  checkboxLabel(row?: T): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  onRowClick(row: T, viewportIndex: number): void {
    if (this.enableRowClick) {
      this.rowClick.emit({row, index: this.dataIndex(viewportIndex)});
    }
  }

  toggleColumnVisibility(column: VsTableColumn<T>) {
    if (!column.hide && this.displayedColumns.length < 2) {
      this.notifications.showSimpleMessage(
        'Table must have at least 1 column displayed',
        undefined,
        {duration: 5000, horizontalPosition: 'center', verticalPosition: 'bottom'}
      );
      return;
    }
    column.hide = !column.hide;
    this.initDisplayColumns();
    if (column.hide && this.tableDataSource.columnFilters[column.field]?.isActive) {
      this.tableDataSource.setColumnFilter(new VsTableColumnFilter<T>(column, false, false, false, []));
    }
    this.saveCache(['columns']);
  }

  exportData() {
    const visibleColumns = this.visibleColumns;
    const negateColumns = visibleColumns.filter((c) => !!c.negateValue);
    const columns: DataColumn[] = visibleColumns.reduce((prev, curr) => {
      const col = { ...curr };
      prev.push(col);
      if (col.compareField) {
        col.title = col.field;
        prev.push(new CurrencyColumn({
          title: col.compareField,
          field: col.compareField,
        }));
      }
      return prev;
    }, [] as VsTableColumn<T>[]);
    const data = this.tableDataSource.data.map((row) => {
      const copy: any = { ...row };
      negateColumns.forEach((col) => copy[col.field] = -copy[col.field]);
      return copy;
    });
    exportToCSV(data, columns, this.cacheKey || this.entityName || 'data');
  }

  reset() {
    let shouldResetColumns = true;
    let shouldResetColumnFilters = true;
    let shouldResetFilter = true;
    let shouldScroll = true;
    let shouldResetSort = true;
    if (this.cacheKey) {
      const cache = this.cacheService.get(this.cacheKey);
      if (cache) {
        shouldResetColumns = !!cache.columns;
        shouldResetColumnFilters = !!cache.columnFilters;
        shouldResetFilter = !!cache.filter;
        shouldScroll = !!cache.scrollTop;
        shouldResetSort = !!cache.sort;
        this.cacheService.delete(this.cacheKey);
      }
    }
    if (shouldResetColumns) {
      this.columns = this.unsortedColumns.map((c) => ({...c}));
      this.initDisplayColumns();
    }
    if (shouldResetColumnFilters) {
      this.tableDataSource.columnFilters = {};
      this.onFilterOrSort();
    }
    if (shouldResetFilter) {
      this.applyFilter('');
    }
    if (shouldScroll) {
      this.viewport?.scrollToOffset(0);
    }
    if (shouldResetSort) {
      this.updateSort({active: '', direction: ''});
    }
  }

  clearAllFilters() {
    this.tableDataSource.clearFilters();
    this.saveCache(['filter', 'columnFilters']);
    this.onFilterOrSort();
  }

  rowSelectionToggle(event: MouseEvent, viewportIndex: number) {
    event.stopPropagation();
    if (this.selection.selected.length > 1 && event.shiftKey) {
      const rows = this.tableDataSource.filteredData;
      const index = this.dataIndex(viewportIndex);
      const previouslySelected = this.selection.selected[this.selection.selected.length - 2];
      const previousIndex = rows.findIndex((r) => r === previouslySelected);
      const start = index > previousIndex ? previousIndex : index;
      const end = index > previousIndex ? index : previousIndex;
      this.selection.select(...rows.slice(start + 1, end));
    }
  }

  drop(event: CdkDragDrop<any>) {
    const displayOffset = this.rowSelection ? 1 : 0;
    const prevDisplayIndex = event.previousIndex + displayOffset;
    const currDisplayIndex = event.currentIndex + displayOffset;
    const prevColIndex = this.columns.findIndex((c) => c.field === this.allDisplayedColumns[prevDisplayIndex]);
    const currColIndex = this.columns.findIndex((c) => c.field === this.allDisplayedColumns[currDisplayIndex]);
    moveItemInArray(this.allDisplayedColumns, prevDisplayIndex, currDisplayIndex);
    moveItemInArray(this.columns, prevColIndex, currColIndex);
    if (this.dragHeaderPosition) {
      event.item.getRootElement().style.top = this.dragHeaderPosition;
    }
    this.saveCache(['columns']);
  }

  toggleExpand(row: T, column: VsTableColumn<T>, viewportIndex: number): void {
    if (isEmpty(row[column.field])) {
      return;
    }
    const index = this.dataIndex(viewportIndex);
    const data: VsTableExpandedDetailConfig<T> = {
      row,
      column,
      index,
      rowHeight: this.itemSize,
      rowName: this.rowName,
    };
    this.dialog.open(ExpandedDialogComponent, {data});
  }

  onColumnFilterChange(filter: VsTableColumnFilter<T>) {
    this.tableDataSource.setColumnFilter(filter);
    this.saveCache(['columnFilters']);
    this.calculateColumnSums();
    this.onFilterOrSort();
  }

  private applyCache() {
    if (this.cacheKey) {
      const cache = this.cacheService.get(this.cacheKey);
      if (cache) {
        if (cache.columns) {
          cache.columns.forEach((col, idx) => {
            const currentIdx = this.columns.findIndex((c) => c.field === col.field);
            if (currentIdx > -1) {
              this.columns[currentIdx].hide = col.hide;
              moveItemInArray(this.columns, currentIdx, idx);
            }
          });
          this.initDisplayColumns();
        }
        if (cache.columnFilters) {
          const columnFilters: {[field: string]: VsTableColumnFilter<T>} = {};
          for (const field in cache.columnFilters) {
            const {column, invert, empty, filterSelected, selected, value, toValue} = cache.columnFilters[field];
            const mappedColumn = this.columns.find((c) => c.field === column.field);
            if (mappedColumn) {
              columnFilters[field] = new VsTableColumnFilter<T>(mappedColumn, invert, empty, filterSelected, selected, value, toValue);
            }
          }
          this.tableDataSource.columnFilters = columnFilters;
        }
        if (cache.filter) {
          this.applyFilter(cache.filter, false);
        }
        if (cache.scrollTop || this.startAtIndex) {
          this.tableDataSource.dataToRender$.pipe(
            skipWhile((data) => !data.length),
            take(1),
          )
            .subscribe(() => setTimeout(() => {
              if (cache.scrollTop) {
                this.viewport.scrollToOffset(cache.scrollTop);
              } else {
                this.viewport.scrollToIndex(this.startAtIndex);
              }
            }));
        }
        const sort = cache.sort || this.defaultSort;
        if (sort) {
          this.skipSortEvent = true;
          this.updateSort(sort);
        }
      }
    }
  }

  private saveCache(propKeys: Array<keyof VsTableCacheState<T>>) {
    if (this.cacheKey) {
      const cache = this.cacheService.get(this.cacheKey) || {};
      propKeys.forEach((key) => {
        switch (key) {
          case 'columns':
            cache[key] = this.columns.map(({field, hide}) => ({field, hide}));
            break;
          case 'filter':
            cache[key] = this.tableDataSource.filter;
            break;
          case 'scrollTop':
            cache[key] = this.viewport.measureScrollOffset();
            break;
          case 'sort':
            cache[key] = {active: this.sort.active, direction: this.sort.direction};
            break;
          case 'columnFilters':
            cache[key] = this.tableDataSource.columnFilters;
            break;
        }
      });
      this.cacheService.update(this.cacheKey, cache);
    }
  }

  private initDisplayColumns() {
    this.allDisplayedColumns = this.visibleColumns.map((c) => c.field);

    if (this.rowSelection) {
      this.allDisplayedColumns.unshift(this.selectColumnDef);
    }

    if (this.rowMenuItems || this.tableMenuItems) {
      this.allDisplayedColumns.push(this.menuColumnDef);
    }
  }

  private updateSort(event: Sort) {
    this.sort.active = event.active;
    this.sort.direction = event.direction;
    this.sort.sortChange.emit(event);
  }

  private calculateColumnSums() {
    const summable = this.columns.filter((c) => !!c.sum);
    if (summable.length) {
      this.columnSums = summable.reduce((prev, curr) => {
        prev[curr.field] = 0;
        if (curr.compareField) {
          prev[curr.compareField] = 0;
        }
        return prev;
      }, {} as {[key: string]: number});
      this.tableDataSource.filteredData.forEach((row) => {
        summable.forEach((column) => {
          this.addToColumnSum(column.field, row);
          if (column.compareField) {
            this.addToColumnSum(column.compareField, row);
          }
        });
      });
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
  }

  private addToColumnSum(field: Extract<keyof T, string>, row: T) {
    this.columnSums[field] = round(this.columnSums[field] + (row[field] as number), 2);
  }

  private onFilterOrSort() {
    this.filterSortChange.emit({
      filtered: this.tableDataSource.isFiltered(),
      sorted: !!this.sort.direction,
    });
  }

  recalculateRow(row: T) {
    const index = this.tableDataSource.data.indexOf(row);
    this.calculateColumns.forEach(setCalcProp(row));
    this.tableDataSource.data.splice(index, 1, {...row});
    this.tableDataSource.data = this.tableDataSource.data.slice();
  }

  removeRow(row: T) {
    const index = this.tableDataSource.data.indexOf(row);
    this.tableDataSource.data.splice(index, 1);
    this.tableDataSource.data = this.tableDataSource.data.slice();
  }
}

function setCalcProp(row: any) {
  return (column: CalculateColumn<any>) => row[calcProp(column)] = column.calculate(row);
}
