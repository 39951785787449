import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {TextColumn, VsTableColumn, VsTableMenuItem, VsTableModule} from "vs-table";
import {AttachedFile} from "../../core/interface/employees.interface";
import {HttpClient} from "@angular/common/http";
import {ConfirmDialogComponent, isTruthy, openFile} from "caig-utils";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {filter, switchMap, tap} from "rxjs";
import {EmployeeEntityService} from "../../core/service/entity-data/employee-entity.service";
import {differenceBy} from "lodash";

@Component({
  selector: 'lib-view-attachments',
  standalone: true,
  imports: [
    VsTableModule,
  ],
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAttachmentsComponent {
  private dialog = inject(MatDialog);
  private dataService = inject(EmployeeEntityService);
  private http = inject(HttpClient);
  data: ViewAttachmentsData = inject(MAT_DIALOG_DATA);

  isLoading = signal(false);
  columns: VsTableColumn<AttachedFile>[] = [
    new TextColumn({
      title: 'File Name',
      field: 'name',
    }),
    new TextColumn({
      title: 'Content Type',
      field: 'contentType',
    }),
  ];
  rowMenuItems: VsTableMenuItem<AttachedFile>[] = [
    {
      name: () => 'Download',
      callback: (row) => this.download(row),
    },
    {
      name: () => 'Delete',
      callback: (row) => this.remove([row]),
    }
  ];
  tableMenuItems: VsTableMenuItem<AttachedFile[]>[] = [
    {
      name: () => 'Download Selection',
      callback: (rows) => this.bulkDownload(rows),
    },
    {
      name: () => 'Delete Selection',
      callback: (rows) => this.remove(rows),
    }
  ];

  private download(file: AttachedFile): void {
    this.isLoading.set(true);
    this.http.get(`/api/attachedFile/${file.id}`, { responseType: 'blob' })
      .subscribe({
        next: (blob) => this.openFile(blob, file.name),
        complete: () => this.isLoading.set(false),
      });
  }

  private bulkDownload(files: AttachedFile[]): void {
    this.isLoading.set(true);
    this.http.post('/api/attachedFile/bulk', files.map((f) => f.id), { responseType: 'blob' })
      .subscribe({
        next: (blob) => this.openFile(blob, this.data.name),
        complete: () => this.isLoading.set(false),
      });
  }

  private remove(files: AttachedFile[]): void {
    const ids = files.map((f) => f.id);
    const request$ = files.length > 1 ?
      this.http.post(`/api/attachedFile/bulkDelete`, ids) :
      this.http.delete(`/api/attachedFile/${files[0].id}`);
    const title = 'Confirm Delete';
    const text = `Are you sure you want to delete ${files.length > 1 ? `all ${files.length} files` : files[0].name}?`;
    this.dialog.open(ConfirmDialogComponent, {data: {text, title}})
      .afterClosed()
      .pipe(
        filter(isTruthy),
        tap(() => this.isLoading.set(true)),
        switchMap(() => request$),
      )
      .subscribe({
        next: () => {
          this.isLoading.set(false);
          if (!this.data.whenSubmitted) {
            this.dataService.getByKey(this.data.id);
          }
          try {
            this.data.attachedFiles = differenceBy(this.data.attachedFiles, files, 'id');
          } catch { }
        },
        complete: () => this.isLoading.set(false),
      });
  }

  private openFile(blob: Blob, filename: string) {
    this.isLoading.set(false);
    openFile(blob, filename);
  }
}

export interface ViewAttachmentsData {
  id: string;
  name: string;
  attachedFiles: AttachedFile[];
  whenSubmitted?: string;
}
