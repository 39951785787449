import {inject, ProviderToken} from "@angular/core";
import {EntityStoreBaseService} from "../entity-data/entity-store-base.service";
import {ResolveFn} from "@angular/router";
import {switchMap, tap, withLatestFrom} from "rxjs";
import {first} from "rxjs/operators";

export const entityResolver: (dataService: ProviderToken<EntityStoreBaseService<any>>) => ResolveFn<any> =
  (dataService) => (route, state) => {
    const service = inject(dataService);
    const entityId = Number(route.paramMap.get('id'));
    return service.loading$.pipe(
      first(),
      switchMap((loading) =>
        service.getByKey(entityId).pipe(
          tap(() => service.setLoading(loading))
        )
      )
    );
  };
