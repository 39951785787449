import {NgModule} from "@angular/core";
import {DefaultDataServiceConfig, EntityDataModule, EntityDispatcherDefaultOptions, Pluralizer} from "@ngrx/data";
import {entityMetadata} from "./entity-metadata";
import {EffectsModule} from "@ngrx/effects";
import {
  baseDataServiceConfig,
  defaultDispatcherOptions,
  EmployeeEffects, employeeFeatureKey, employeeReducers,
  EntityEffects,
  EntityPluralizer
} from "shared-modules";
import {StoreModule} from "@ngrx/store";

@NgModule({
  imports: [
    EntityDataModule.forRoot({entityMetadata}),
    EffectsModule.forFeature([EntityEffects, EmployeeEffects]),
    StoreModule.forFeature(employeeFeatureKey, employeeReducers),
  ],
  providers: [
    {provide: Pluralizer, useClass: EntityPluralizer},
    {provide: DefaultDataServiceConfig, useValue: baseDataServiceConfig },
    {provide: EntityDispatcherDefaultOptions, useValue: defaultDispatcherOptions },
  ],
})
export class EntityStoreModule {

}
