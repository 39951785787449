import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  ChangesColumn,
  DateColumn,
  TextColumn,
  VsTableColumn,
  VsTableModule
} from "vs-table";
import {AsyncPipe, NgIf} from "@angular/common";
import {EventsDataService} from "../service/events-data.service";
import {map, shareReplay, startWith, switchMap} from "rxjs";
import {BaseField, DateRangeField, DynamicFormModule} from "dynamic-form";
import {ActivatedRoute, Router} from "@angular/router";
import {AppEvent} from "../../core/interface/events.interface";
import {HttpParams} from "@angular/common/http";
import {DateService} from "date";
import {CORE_CONFIG} from "../../core/consts/injection-tokens";
import {AppPortal} from "../../core/enum/session.enums";

@Component({
  selector: 'lib-events-list',
  standalone: true,
  imports: [
    DynamicFormModule,
    VsTableModule,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListComponent {
  private eventsService = inject(EventsDataService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private dateService = inject(DateService);
  private config = inject(CORE_CONFIG);
  filterActive$ = this.route.queryParams.pipe(
    map((queryParams) => !!Object.keys(queryParams).length),
  );
  events$ = this.route.queryParams.pipe(
    switchMap((queryParams, index) => {
      const fromObject: any = { ...queryParams };
      if (index === 0) {
        fromObject.fromDate = this.formModel.dates.start;
      }
      if (this.config.portal === AppPortal.CAIG) {
        fromObject.scopeToUser = false; // @TODO - temp workaround, remove when new settlement type is added to handle this
      }
      return this.eventsService.getAll(new HttpParams({fromObject}))
        .pipe(startWith(null));
    }),
    shareReplay(),
  );
  columns: VsTableColumn<AppEvent>[] = [
    new TextColumn({
      title: 'Employee',
      field: 'employeeName',
    }),
    new DateColumn({
      title: 'Date',
      field: 'whenCreated',
      format: 'short',
      filter: false,
    }),
    new TextColumn({
      title: 'Message',
      field: 'message',
      expandable: true,
    }),
    new TextColumn({
      title: 'Type',
      field: 'description',
    }),
    new TextColumn({
      title: 'User',
      field: 'user',
    }),
    new ChangesColumn({
      title: 'Changes',
      field: 'changes',
    }),
    new TextColumn({
      title: 'Employee Payment',
      field: 'employeePaymentId',
    }),
    new TextColumn({
      title: 'Payroll',
      field: 'payrollId',
    })
  ];
  formModel = {
    dates: {
      start: this.route.snapshot.queryParamMap.get('fromDate') || this.dateService.get().current().month().start(),
      end: this.route.snapshot.queryParamMap.get('toDate'),
    },
  };
  formFields: BaseField<any>[][] = [
    [
      new DateRangeField({
        name: 'dates',
        label: 'Date Range',
        defaultValue: {
          start: this.dateService.get().current().month().start(),
        },
        onChange: (value) => {
          const fromDate = value.start;
          const toDate = value.end;
          const queryParams = fromDate || toDate ?
            { fromDate, toDate } : undefined;
          this.router.navigate([], {queryParams, replaceUrl: true});
        }
      }),
    ],
  ];

  viewEmployee(event: AppEvent): void {
    this.router.navigate(['/employees', event.employeeId]);
  }
}
