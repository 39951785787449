import {ResolveFn} from "@angular/router";
import {inject} from "@angular/core";
import {first, map, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {selectEmployeeState} from "../core/store/employee/employee.selectors";
import {EmployeeActions} from "../core/store/employee/action-types";

export const employeesBatchResolver: ResolveFn<string | null> = (route, state) => {
  const store = inject(Store);
  return store.select(selectEmployeeState).pipe(
    first((state) => !state.loading),
    map((state) => state.employees ? null : state.batchId),
    tap((batchId) => {
      if (batchId) {
        store.dispatch(EmployeeActions.loadBatch({batchId}));
      }
    }),
  );
};
