import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState} from './auth.state';

export const authFeatureKey = 'auth';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectAuthToken = createSelector(
  selectAuthState,
  (state) => state.token
);

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state) => state.loading
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state) => state.error
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state) => state.authenticated,
);

export const selectAuthPassword = createSelector(
  selectAuthState,
  (state) => state.loginPassword,
);

export const selectIsMsalAuth = createSelector(
  selectAuthToken,
  selectIsAuthenticated,
  (token, authenticated) => authenticated && !token,
);

export const selectIsTraditionalAuth = createSelector(
  selectAuthToken,
  selectIsAuthenticated,
  (token, authenticated) => authenticated && !!token,
);
