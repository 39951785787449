import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {combineLatest, filter, Observable, startWith} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {selectSession, selectSessionError, selectSessionLoading} from '../../store/core/core.selectors';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {AsyncPipe, NgIf} from "@angular/common";
import {
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  ResolveStart,
  RouteConfigLoadStart,
  Router,
  RouterModule
} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {NavigationMenuComponent} from "../navigation-menu/navigation-menu.component";
import {SessionErrorComponent} from "../session-error/session-error.component";
import {ThemeToggleComponent} from "../theme-toggle/theme-toggle.component";
import {UserMenuComponent} from "../user-menu/user-menu.component";
import {CaseSelectorComponent} from "../case-selector/case-selector.component";
import {UpdateTimerComponent} from "../update-timer/update-timer.component";
import {ServiceWorkerService} from "../../service/service-worker.service";
import {MatBottomSheet, MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {PhoneDialerComponent} from "../../../phone-dialer/phone-dialer.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CORE_CONFIG} from "../../consts/injection-tokens";
import {AppPortal} from "../../enum/session.enums";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    RouterModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatBottomSheetModule,
    NavigationMenuComponent,
    SessionErrorComponent,
    ThemeToggleComponent,
    UserMenuComponent,
    CaseSelectorComponent,
    UpdateTimerComponent,
  ],
})
export class LayoutComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);
  private store = inject(Store);
  private router = inject(Router);
  private bottomSheet = inject(MatBottomSheet);
  private config = inject(CORE_CONFIG);
  swService = inject(ServiceWorkerService);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  sessionError$ = this.store.select(selectSessionError);
  session$ = this.store.select(selectSession);
  isLoading$!: Observable<boolean>;
  showDialer = this.config.portal === AppPortal.CAIG;

  ngOnInit() {
    const sessionLoading$ = this.store.select(selectSessionLoading);
    const routerLoading$ = this.router.events.pipe(
      map((event) => {
        switch (true) {
          case event instanceof RouteConfigLoadStart:
          case event instanceof ActivationStart:
          case event instanceof ResolveStart:
            return true;
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
            return false;
        }
        return null;
      }),
      filter((shouldLoad): shouldLoad is boolean => shouldLoad !== null),
      startWith(false),
    );

    this.isLoading$ = combineLatest([sessionLoading$, routerLoading$]).pipe(
      map(([a, b]) => a || b),
    );
  }

  openDialer() {
    this.bottomSheet.open(PhoneDialerComponent);
  }
}
